import { ShareLinkStatus } from "models/shareLink";
import { UploadLinkStatus } from "models/uploadly";
import React from "react";

export const getLinkStatusString = (linkStatus: UploadLinkStatus | ShareLinkStatus, translation: any) => {
    if (linkStatus == UploadLinkStatus.ACTIVE) {
      return translation("upload_link_status_active");
    } else if (linkStatus== UploadLinkStatus.DISABLED) {
      return translation("upload_link_status_disabled");
    } else if (linkStatus == UploadLinkStatus.EXPIRED) {
      return translation("upload_link_status_expired");
    }
};

export const getLinkStatusColor = (linkStatus: UploadLinkStatus | ShareLinkStatus) => {
  if (linkStatus == UploadLinkStatus.ACTIVE) {
    return "#66CC00";
  } else if (linkStatus == UploadLinkStatus.DISABLED) {
    return "coral";
  } else if (linkStatus == UploadLinkStatus.EXPIRED) {
    return "red";
  }
};

export const getLinkStatusDotColor = (linkStatus: UploadLinkStatus | ShareLinkStatus) => {
  if (linkStatus == UploadLinkStatus.ACTIVE) {
    return "chartreuse";
  } else if (linkStatus == UploadLinkStatus.DISABLED) {
    return "coral";
  } else if (linkStatus == UploadLinkStatus.EXPIRED) {
    return "red";
  }
};

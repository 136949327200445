import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import {
  Box,
  Card,
  CardActionArea,
  Drawer,
  Grid,
  IconButton,
  Link,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { DEBUG, getSharedFileDownloadLink } from "configuration";
import {
  retrieveShareLinkFileItemsForIdPagedGql,
  retrieveUploadItemForLinkIdPagedGql,
} from "graphql/queries";
import { UploadItem, UploadLink } from "models/uploadly";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { formatIso8601date } from "shared/dataUtils";
import { useTranslation } from "react-i18next";

import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import FolderZipOutlinedIcon from "@mui/icons-material/FolderZipOutlined";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import AudiotrackOutlinedIcon from "@mui/icons-material/AudiotrackOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import EmptyUploadItemsResult from "res/illustrations/illustration_upload_items_empty.svg";
import { useResponsive } from "hooks/use-responsive";
import { ShareLink, ShareLinkFile } from "models/shareLink";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DownloadIcon from "@mui/icons-material/Download";
import { deleteShareLinkFileGql } from "graphql/mutations";
import { useDispatch } from "react-redux";
import { showSnackbar } from "store/view";
import { updateShareLinkFilesCacheUponDelete } from "graphql/gqlcachehelper";

interface ShareLinkFileItemsContainerProps {
  shareLinkId: string;
  shareLinkExpired: boolean;
}

const PAGE_SIZE = 20;
const ShareLinkFileItemsContainer = ({
  shareLinkId,
  shareLinkExpired,
}: ShareLinkFileItemsContainerProps) => {
  const fetchMoreOngoing = React.useRef(false);
  const lgUp = useResponsive("up", "lg");

  const { t } = useTranslation();
  const theme = useTheme();
  const [allItemsRetrieved, setAllItemsRetrieved] = React.useState(false);
  const [itemsListEmpty, setItemsListEmpty] = React.useState(false);
  // const [selectedItem, setSelectedItemContainer] = React.useState<UploadItem | null>(null);
  const [clickedSharedFile, setClickedSharedFile] = React.useState<
    ShareLinkFile | undefined
  >();

  const lastFetchedPage = React.useRef(1);

  const shareLinkFilesResult = useQuery(
    retrieveShareLinkFileItemsForIdPagedGql,
    {
      variables: {
        id: shareLinkId,
        page: 1,
        pageSize: PAGE_SIZE,
      },
    }
  );

  React.useEffect(() => {
    if (
      shareLinkFilesResult.data &&
      !shareLinkFilesResult.loading &&
      !shareLinkFilesResult.error
    ) {
      setAllItemsRetrieved(
        shareLinkFilesResult.data.shareLinkFilesPaged.length % PAGE_SIZE != 0 ||
          shareLinkFilesResult.data.shareLinkFilesPaged.length == 0
      );
      setItemsListEmpty(
        shareLinkFilesResult.data.shareLinkFilesPaged.length == 0
      );
    }
  }, [shareLinkFilesResult]);

  const fetchMoreItems = React.useCallback(() => {
    if (fetchMoreOngoing.current) {
      if (DEBUG) {
        console.log("fetchMoreItems already ongoing");
      }
      return;
    }
    fetchMoreOngoing.current = true;

    if (allItemsRetrieved) {
      if (DEBUG) {
        console.log("Already retrieved everything");
      }
      fetchMoreOngoing.current = false;
      return;
    }
    const nextPageToFetch = lastFetchedPage.current + 1;

    if (DEBUG) {
      console.log("Fetch more data for page " + nextPageToFetch);
    }
    shareLinkFilesResult
      .fetchMore({
        variables: {
          linkId: shareLinkId,
          page: nextPageToFetch,
          pageSize: PAGE_SIZE,
        },
      })
      .then((result) => {
        if (DEBUG) {
          console.log("Fetch more result %o", result);
        }
        if (
          result.data &&
          result.data.shareLinkFilesPaged &&
          result.data.shareLinkFilesPaged.length == 0
        ) {
          setAllItemsRetrieved(true);
        }
        lastFetchedPage.current = nextPageToFetch;
        fetchMoreOngoing.current = false;
      })
      .catch((e) => {
        //setFetchOngoing(false)
        fetchMoreOngoing.current = false;
      });
    //}
  }, [
    shareLinkFilesResult,
    shareLinkFilesResult.data,
    allItemsRetrieved,
    fetchMoreOngoing.current,
    shareLinkId,
  ]);

  const handleOnScroll = React.useCallback(
    (e: any) => {
      const bottom =
        e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
      if (bottom) {
        fetchMoreItems();
      }
    },
    [fetchMoreItems]
  );

  const [remainingHeight, setRemainingHeight] = useState(0);
  const targetRef = useRef<any>(null);
  const calculateHeight = () => {
    const viewportHeight = window.innerHeight;
    const topOffset = targetRef.current.getBoundingClientRect().top;
    const computedHeight = viewportHeight - topOffset;

    setRemainingHeight(computedHeight);
  };

  useEffect(() => {
    calculateHeight();
    window.addEventListener("resize", calculateHeight);

    return () => {
      window.removeEventListener("resize", calculateHeight);
    };
  }, []);

  return (
    <Box
      sx={{
        py: theme.spacing(1),
        paddingBottom: theme.spacing(4),
        // position: "relative",
        display: "flex",
        flexDirection: "row",
        // overflowY: !isIntersecting ? "scroll" : undefined,
        // overflowY: undefined,
        // overflowY: "hidden",
        //  overflow: 'scroll',
        width: "100%",
        flex: "1",
      }}
    >
      <Box
        ref={targetRef}
        onScroll={handleOnScroll}
        sx={{
          display: "flex",
          width: "100%",
          //width: clickedSharedFile ? "50%" : "100%",
          //  background: "yellow",
          height: remainingHeight,
          flex: "1",
          overflowX: "hidden",
          overflowY: "auto",
          justifyContent: "top",
          flexDirection: "column",
          paddingTop: theme.spacing(4),
          py: theme.spacing(1),
          transition: theme.transitions.create("all", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
        }}
      >
        <Grid container spacing={1}>
          {shareLinkFilesResult &&
            shareLinkFilesResult.data &&
            shareLinkFilesResult.data.shareLinkFilesPaged &&
            shareLinkFilesResult.data.shareLinkFilesPaged.map(
              (item: ShareLinkFile, index: number) => {
                return (
                  <ShareLinkFileItem
                    item={item}
                    index={index}
                    shareLinkId={shareLinkId}
                    shareLinkExpired={shareLinkExpired}
                    clickedShareLinkFile={clickedSharedFile}
                    onShareLinkFileChanged={(item) => {
                      setClickedSharedFile(item);
                    }}
                  />
                );
              }
            )}
        </Grid>

        {itemsListEmpty && (
          <Card
            sx={{
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "top",
              paddingTop: "10%",
            }}
          >
            <img
              src={EmptyUploadItemsResult}
              style={{
                width: "400px",
              }}
            />
            <Typography
              sx={{
                marginTop: theme.spacing(1),
              }}
              variant="subtitle2"
            >
              {t("upload_link_detail_empty_upload_items_title")}
            </Typography>
          </Card>
        )}
      </Box>
    </Box>
  );
};

interface ShareLinkFileItemProps {
  item: ShareLinkFile;
  index: number;
  shareLinkId: string;
  shareLinkExpired: boolean;
  clickedShareLinkFile: ShareLinkFile | undefined;
  onShareLinkFileChanged: (clickedShareLinkFile: ShareLinkFile) => void;
}

const ShareLinkFileItem = ({
  item,
  index,
  shareLinkId,
  shareLinkExpired,
  clickedShareLinkFile,
  onShareLinkFileChanged,
}: ShareLinkFileItemProps) => {
  const [itemHovered, setItemHovered] = useState(false);
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const client = useApolloClient();

  const [deleteShareLinkFileItem, deleteShareLinkFileItemResult] = useMutation(
    deleteShareLinkFileGql
  );

  const getIconForFileType = useCallback((fileType: string) => {
    if (fileType.startsWith("image/")) {
      return <ImageOutlinedIcon />;
    } else if (fileType.startsWith("video/")) {
      return <VideocamOutlinedIcon />;
    } else if (fileType.startsWith("application/pdf")) {
      return <PictureAsPdfOutlinedIcon />;
    } else if (fileType.startsWith("application/zip")) {
      return <FolderZipOutlinedIcon />;
    } else if (fileType.startsWith("audio/")) {
      return <AudiotrackOutlinedIcon />;
    } else {
      return <InsertDriveFileOutlinedIcon />;
    }
  }, []);

  const handleDeleteShareLinkFile = React.useCallback(() => {
    const itemPage = index == 0 ? 1 : Math.ceil(index / PAGE_SIZE);

    deleteShareLinkFileItem({
      variables: {
        id: item.id,
        page: itemPage,
        pageSize: PAGE_SIZE,
      },
    })
      .then((result) => {
        if (result.data && result.data.deleteShareLinkFile.success) {
          dispatch(
            showSnackbar({
              message: t("operation_success_generic"),
              loading: false,
            })
          );
          const newPage = result.data.deleteShareLinkFile.newPage;
          updateShareLinkFilesCacheUponDelete(
            client.cache,
            itemPage,
            PAGE_SIZE,
            shareLinkId,
            newPage
          );
        } else {
          dispatch(
            showSnackbar({
              message: t("operation_failed_generic"),
              loading: false,
            })
          );
        }
      })
      .catch((e) => {
        dispatch(
          showSnackbar({
            message: t("operation_failed_generic"),
            loading: false,
          })
        );
      });
  }, [index]);

  const handleDownloadFile = useCallback(() => {
    window.open(getSharedFileDownloadLink(item.id), "_self");
  }, []);

  return (
    <Grid item key={item.id} xs={12} sm={12} md={12}>
      <Card
        sx={{
          boxShadow:
            "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px",
          borderRadius: "8px",
          border:
            clickedShareLinkFile && clickedShareLinkFile.id == item.id
              ? `2px solid ${theme.palette.primary.main}`
              : "none",
        }}
        onMouseEnter={() => {
          setItemHovered(true);
        }}
        onMouseLeave={() => {
          setItemHovered(false);
        }}
      >
        <CardActionArea
          sx={{
            //padding: "24px",
            py: theme.spacing(1),
            px: theme.spacing(4),
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            //justifyContent: "space",
            width: "100%",
          }}
          onClick={() => {
            onShareLinkFileChanged(item);
          }}
        >
          <Box
            sx={{
              width: "25%",
              flex: "1",
              display: "flex",
              flexDirection: "rown",
              alignItems: "center",
            }}
          >
            {" "}
            {getIconForFileType(item.fileType)}
            <Typography
              sx={{
                marginLeft: theme.spacing(2),
                width: "25%",
                flex: "1",
                wordWrap: "break-word",
                textOverflow: "ellipsis",
                fontWeight: "bold",
              }}
              variant="caption"
            >
              {item.fileName}
            </Typography>
          </Box>
          <Typography
            sx={{
              flex: "1",
              boxSizing: "border-box",
              wordWrap: "break-word",
              textAlign: "center",
            }}
            variant="caption"
          >
            {t("uploaded_at", {
              date: formatIso8601date(item.createdAt),
            })}
          </Typography>
          {/*   <Typography
                sx={{
                  flex: "1",
                  boxSizing: "border-box",
                  wordWrap: "break-word",
                }}
                variant="caption"
              >
                {item.fileType}
              </Typography> */}

          {/*  <Typography
                sx={{
                  flex: "1",
                  boxSizing: "border-box",
                  wordWrap: "break-word",
                  textAlign: "center",
                }}
                variant="caption"
              >
                {t("by")}{" "}
                {item.uploader
                  ? item.uploader.firstName +
                    " " +
                    item.uploader.lastName
                  : t("anonymous_user_title")}
              </Typography> */}

          {itemHovered && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Tooltip title={t("delete_action")}>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteShareLinkFile();
                  }}
                >
                  <DeleteOutlineIcon
                    sx={{
                      width: "16px",
                      height: "16px",
                    }}
                    onClick={() => {
                      /*  navigator.clipboard.writeText(
                        //getPublicShareLinkFromToken(item.token)
                      ); */
                    }}
                  />
                </IconButton>
              </Tooltip>
              {!shareLinkExpired && (
                <Tooltip title={t("download_action")}>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDownloadFile();
                    }}
                  >
                    <DownloadIcon
                      sx={{
                        width: "16px",
                        height: "16px",
                      }}
                    />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          )}
        </CardActionArea>
      </Card>
    </Grid>
  );
};

export default React.memo(ShareLinkFileItemsContainer);

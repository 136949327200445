import React, { useCallback, useState } from "react";
import GoogleIcon from "res/icons/google_icon.svg";
import { useTranslation } from "react-i18next";
import {
  LoginApiResult,
  LoginStatusCode,
  fetchUserData,
  getCsrf,
  getGoogleUserInfoFromAccessToken,
  handleGoogleLoginResponse,
  login,
} from "api/authApi";
import {
  DEBUG,
  GOOGLE_LOGIN_KEY,
  PASSWORD_MIN_LENGTH,
  SERVER_URL,
} from "configuration";
import {
  Alert,
  Box,
  Button,
  Card,
  Container,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { UserObj } from "models/user";
import { loginUser as loginReducerAction } from "store/auth";
import { setPathAfterLogin, startGooglelogin } from "store/view";
import { RootState } from "store";
import {
  LOGIN,
  MAINBOARD,
  REQUEST_RESET_PASSWORD,
  ROOT,
  SIGNUP,
} from "navigation/Constants";
import {
  CredentialResponse,
  GoogleLogin,
  TokenResponse,
  useGoogleLogin,
} from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import { LoadingButton } from "@mui/lab";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Link from "@mui/material/Link";
import UploadlyIcon from "res/icons/uploadly-logo.svg";
import { isValidEmail } from "shared/dataUtils";
import { Helmet } from "react-helmet-async";
import CreateAccountConfirmationDialog from "components/ui/dialogs/CreateAccountConfirmationDialog";

const INITIAL_LOGIN_STATUS = {
  loginFailed: false,
  errorMsg: "",
};

interface InputErrors {
  emailError: string | null;
  passwordError: string | null;
}
const LoginPage = () => {
  const userGoogleAccountFirstName = React.useRef();
  const userGoogleAccountEmail = React.useRef();
  const userGoogleAccessToken = React.useRef();

  const emailRef = React.useRef<any>();
  const passwordRef = React.useRef<any>();
  const loginWithGoogleButtonRef = React.useRef<any>();
  const [inputsErrors, setInputsErrors] = useState<InputErrors>({
    emailError: null,
    passwordError: null,
  });

  const theme = useTheme();
  const [loginsStatus, setLoginStatus] = useState<any>(INITIAL_LOGIN_STATUS);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const afterLoginPath = useSelector(
    (state: RootState) => state.view.pathAfterLogin
  );
  const googleLogin = useSelector(
    (state: RootState) => state.view.startGoogleLogin
  );

  React.useEffect(() => {
    if (googleLogin) {
      setTimeout(() => {
        loginWithGoogleButtonRef.current.click();
        dispatch(startGooglelogin(false));
      }, 1000); //ugly workaround T-T
    }
  }, [googleLogin]);
  const [showPassword, setShowPassword] = useState(false);


  const validateInputs = useCallback(() => {
    // var inputsValid = true;
    const email = emailRef.current.value;
    const password = passwordRef.current.value;

    /*   if (!email || email.length == 0) {
      setInputsErrors({ ...inputsErrors, emailError: t("email_invalid") });
    } */
    if (!isValidEmail(email)) {
      setInputsErrors({ ...inputsErrors, emailError: t("email_invalid") });
      return false;
    }

    if (password.length < PASSWORD_MIN_LENGTH) {
      setInputsErrors({
        ...inputsErrors,
        passwordError: t("password_invalid"),
      });
      return false;
    }

    return true;
  }, [emailRef.current, passwordRef.current, inputsErrors]);

  const handleLogin = useCallback(() => {
    const result = validateInputs();
    if (!result) {
      return;
    }
    const email = emailRef.current.value;
    const password = passwordRef.current.value;
    setIsLoading(true);

    if (result) {
      login(email, password)
        .then((result: LoginApiResult) => {
          setIsLoading(false);

          console.log("login result : " + JSON.stringify(result));
          if (result.statusCode > 0) {
            setLoginFailed(getLoginError(result.statusCode));
          } else if (result.statusCode == 0) {
            setLoginSucceeded();
          }
          if (result == null) {
            console.log("login failed !");
          } else {
            console.log("login result " + JSON.stringify(result));
          }
        })
        .catch((e) => {
          setIsLoading(false);
        });
    }
  }, [validateInputs]);

  const getLoginError = useCallback((statusCode: number) => {
    switch (statusCode) {
      case LoginStatusCode.NO_ACCOUNT_FOUND_FOR_EMAIL.valueOf():
        return t("login_no_account_exists_error");
      case LoginStatusCode.WRONG_PASSWORD.valueOf():
        return t("login_wrong_password_error");
      default:
        return t("login_unknown_error");
    }
  }, []);

  const loginWithGoogle = useGoogleLogin({
    //scope: "https://www.googleapis.com/auth/drive.file",
    onSuccess: (codeResponse: TokenResponse) => {
      console.log("Got the response here %o", codeResponse);
      console.log("After dec");

      // const decoded = jwtDecode(codeResponse.access_token!!);
      console.log("After decoding" + JSON.stringify(codeResponse.access_token));
      onGoogleLoginSuccess(codeResponse.access_token, false);
      /* getGoogleUserInfoFromAccessToken(codeResponse.access_token).then(
        (result) => {
          console.log("Fetched user data and got the result here %o", result);
        }
      ); */
    },

    onError: (error) => console.log("Login Failed:", error),
  });
  /*  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      console.log("code response is %o", codeResponse);
    },
    onError: (error: any) => console.log("Login Failed:", error),
  }); */
  const [openCreateAccount, setOpenCreateAccount] = React.useState(false);

  const dispatchLogin = (user: UserObj) => {
    dispatch(loginReducerAction(user));
  };

  const setLoginFailed = useCallback((errorMsgArg: string) => {
    setLoginStatus({ loginFailed: true, errorMsg: errorMsgArg });
    setIsLoading(false);
  }, []);

  /* React.useEffect(() => {
    if (user){

    }
  }, []) */
  const setLoginSucceeded = () => {
    fetchUserData()
      .then((user: UserObj) => {
        dispatchLogin(user);
        if (DEBUG) {
          console.log("will move to path " + afterLoginPath);
        }

        /*  const uri = SERVER_URL + "/graphql";
        const uploadLink = createUploadLinkGql({
          uri: uri,
          credentials: "include",
          headers: {
            "X-CSRFToken": Cookies.get("csrftoken"),
            // Authorization:
            //   "Token 3d9e16e9bd49539aefb2648535e3529ce9920293b372c7c6d7d8a4d2e0a67dbc",
          },
        });

        client.setLink(uploadLink); */
        console.log("Path after login is " + afterLoginPath);
        if (afterLoginPath && afterLoginPath.length > 0) {
          console.log("will navigate to " + afterLoginPath);
        //  window.open(afterLoginPath,"_self");
          navigate(afterLoginPath);
          //dispatch(setPathAfterLogin(null));
        } else {
          console.log("Will navigate to the home");
          navigate(MAINBOARD);
        }
        if (DEBUG) console.log("User data is  " + JSON.stringify(user));
      })
      .catch((err) => {
        console.log("Caught an error while fetch user data" + err);
      });
  };

  const onGoogleLoginSuccess = (
    access_token: any,
    createAccountIfNotExists = false
  ) => {
    setLoginStatus(INITIAL_LOGIN_STATUS);
    setIsLoading(true);
    //console.log("got an access token" + access_token);

    setTimeout(() => {
      //this is just a hack.. to avoid error on backend token used too early
      const response = handleGoogleLoginResponse(
        access_token,
        createAccountIfNotExists
      );
      response
        .then((result: any) => {
          console.log("Google login result  %o", result);
          if (result.status === 200 && result.data.status_code == 0) {
            setLoginSucceeded();
          } else if (result.status == 200 && result.data.status_code == 5) {
            userGoogleAccountFirstName.current = result.data.first_name;
            userGoogleAccountEmail.current = result.data.email;
            userGoogleAccessToken.current = access_token;
            setOpenCreateAccount(true);
          } else {
            setLoginFailed(t("google_login_failed_msg"));
          }
          if (DEBUG) {
            console.log("Got a result :" + JSON.stringify(result));
          }
          setIsLoading(false);
        })
        .catch((e: any) => {
          setIsLoading(false);
        });
    }, 2000);
  };

  return (
    <>
      <Container
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
        maxWidth="xs"
      >
        <Helmet>
          <title>{t("login_action")} | Uploadly</title>
        </Helmet>
        <img
          style={{ position: "absolute", top: "16px", left: "64px" }}
          height="48px"
          width="48px"
          src={UploadlyIcon}
          onClick={() => {
            navigate(ROOT);
          }}
        />
        <Card
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            px: theme.spacing(4),
            py: theme.spacing(2),
            boxShadow:
              "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
          }}
        >
          <Typography
            sx={{
              marginBottom: "16px",
            }}
            variant="h4"
          >
            {t("sign_in")}
          </Typography>

          {loginsStatus.loginFailed && (
            <Alert
              sx={{ marginBottom: theme.spacing(2) }}
              id="error-alert"
              severity="error"
            >
              {" "}
              {loginsStatus.errorMsg}
            </Alert>
          )}

          <Button
            ref={loginWithGoogleButtonRef}
            variant="contained"
            sx={{
              textTransform: "uppercase",
              padding: theme.spacing(1),
              px: theme.spacing(3),
            }}
            onClick={() => {
              loginWithGoogle();
            }}
          >
            <Box
              sx={{
                background: "white",
                display: "flex",
                justifyContent: "center",
                padding: "4px",
                borderRadius: "4px",
                marginRight: theme.spacing(4),
              }}
            >
              <img src={GoogleIcon} />
            </Box>{" "}
            {t("continue_with_google_title")}
          </Button>

          {/*  <button
            onClick={() => {
              loginWithGoogle();
            }}
            style={{
              width: "100%",
              backgroundColor: "rgb(255, 255, 255)",
              display: "inline-flex",
              alignItems: "center",
              color: "rgba(0, 0, 0, 0.54)",
              // boxShadow:
              //   "rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px",
              padding: "0px",
              borderRadius: "4px",
              border: "1px solid transparent",
              fontSize: "14px",
              fontWeight: 500,
              borderWidth: "1px",
              borderColor: "gray",
              fontFamily: "Roboto, sans-serif",
            }}
            type="button"
          >
            <div
              style={{
                marginRight: "10px",
                background: "rgb(255, 255, 255)",
                padding: "10px",
                borderRadius: " 2px",
              }}
            >
              <img src={GoogleIcon} />
            </div>
            <span
              style={{
                width: "100%",
                padding: "10px 10px 10px 0px",
                fontWeight: 500,
              }}
            >
              {t("continue_with_google_title")}
            </span>
          </button> */}

          <Divider sx={{ my: 3 }}>
            <Typography
              variant="body2"
              sx={{ color: "text.secondary", textTransform: "uppercase" }}
            >
              {t("or")}
            </Typography>
          </Divider>
          <Stack spacing={3}>
            <TextField
              inputRef={emailRef}
              error={Boolean(inputsErrors.emailError)}
              helperText={inputsErrors.emailError}
              name="email"
              placeholder={t("email")}
            />

            <TextField
              inputRef={passwordRef}
              error={Boolean(inputsErrors.passwordError)}
              helperText={inputsErrors.passwordError}
              name="password"
              placeholder={t("password")}
              type={showPassword ? "text" : "password"}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  handleLogin();
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            sx={{ my: 3 }}
          >
            <Link
              variant="subtitle2"
              underline="hover"
              onClick={() => {
                navigate(REQUEST_RESET_PASSWORD);
              }}
            >
              {t("forgot_password_question")}
            </Link>
          </Stack>

          <LoadingButton
            fullWidth
            type="submit"
            variant="contained"
            onClick={handleLogin}
            loading={isLoading}
          >
            {t("login")}
          </LoadingButton>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "8px",
            }}
          >
            <Link
              variant="subtitle2"
              sx={{ ml: 0.5, cursor: "pointer" }}
              onClick={() => {
                navigate(SIGNUP);
              }}
            >
              {t("sign_up")}
            </Link>
          </Box>
        </Card>
        {userGoogleAccountEmail.current &&
          userGoogleAccountFirstName.current &&
          userGoogleAccessToken.current && (
            <CreateAccountConfirmationDialog
              open={openCreateAccount}
              userEmail={userGoogleAccountEmail.current}
              userFirstName={userGoogleAccountFirstName.current}
              googleAccessToken={userGoogleAccessToken.current}
              handleModalClose={() => {
                setOpenCreateAccount(false);
                userGoogleAccountEmail.current = undefined;
                userGoogleAccountFirstName.current = undefined;
              }}
              handleCreateNewAccount={(googleAccessToken: string) => {
                setOpenCreateAccount(false);
                userGoogleAccountEmail.current = undefined;
                userGoogleAccountFirstName.current = undefined;
                userGoogleAccessToken.current = undefined;
                onGoogleLoginSuccess(googleAccessToken, true);
              }}
            />
          )}
      </Container>
    </>
  );
};

export default React.memo(LoginPage);

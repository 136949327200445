import {
  Box,
  Button,
  Card,
  CardActionArea,
  CircularProgress,
  Grid,
  IconButton,
  List,
  ListItem,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  DEBUG,
  getUploadLinkDetailViewRelativeUrlFromId,
  getUploadLinkUrlFromToken,
} from "configuration";
import { useQuery } from "@apollo/client";
import {
  retrieveUploadLinksForConnectedStoragePagedGql,
  retrieveUploadLinksPagedGql,
} from "graphql/queries";
import {
  DROPBOX_INTEGRATION,
  GOOGLE_DRIVE_INTEGRATION,
  ONEDRIVE_INTEGRATION,
  getIconForStorageProvider,
} from "pages/storages/storageProviderIds";
import {
  formatIso8601date,
  formatIso8601dateWithTime,
  getRemainingTime,
} from "shared/dataUtils";
import {
  StorageProviderType,
  UploadLink,
  UploadLinkStatus,
} from "models/uploadly";
import DestinationFolderBadge from "components/ui/DestinationFolderBadge";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import CreateUploadLinkModal from "components/modals/CreateUploadLinkModal";
import { useNavigate } from "react-router-dom";
import CopyButtonToolTipped from "components/ui/CopyButtonToolTipped";
import LinkIllustration from "res/illustrations/link_illustration.svg";
import { getLinkStatusColor, getLinkStatusString } from "shared/utility";

const PAGE_SIZE = 15;

export enum UploadLinksQueryType {
  ALL = 0,
  FOR_CONNECTED_STORAGE = 1,
}

interface UploadlyLinksContainerProps {
  queryType?: UploadLinksQueryType;
  connectedStorageId?: string | null;
}

const UploadlyLinksContainer = ({
  queryType = UploadLinksQueryType.ALL,
  connectedStorageId = null,
}: UploadlyLinksContainerProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const navigate = useNavigate();
  const fetchMoreOngoing = React.useRef(false);

  const [allItemsRetrieved, setAllItemsRetrieved] = React.useState(false);
  const [displayCreateNewLink, setDisplayCreateNewLink] = React.useState(false);
  const [itemsListEmpty, setItemsListEmpty] = React.useState(false);
  const lastFetchedPage = React.useRef(1);
  const [emptyResult, setEmptyResult] = useState(false);

  const getQueryType = useCallback(
    (queryType: UploadLinksQueryType) => {
      if (queryType == UploadLinksQueryType.FOR_CONNECTED_STORAGE) {
        return retrieveUploadLinksForConnectedStoragePagedGql;
      }
      return retrieveUploadLinksPagedGql;
    },
    [queryType]
  );

  const getVariables = useCallback(
    (queryType: UploadLinksQueryType, page: number) => {
      if (queryType == UploadLinksQueryType.FOR_CONNECTED_STORAGE) {
        return {
          variables: {
            id: connectedStorageId,
            page: page,
            pageSize: PAGE_SIZE,
          },
        };
      }
      return {
        variables: {
          page: page,
          pageSize: PAGE_SIZE,
        },
      };
    },
    [queryType, lastFetchedPage.current]
  );

  const uploadLinksQueryResult = useQuery(
    getQueryType(queryType),
    getVariables(queryType, 1)
  );

  const getUploadLinksArray = (queryResultData: any) => {
    if (queryResultData) {
      if (queryResultData.allUploadLinksPaged) {
        return queryResultData.allUploadLinksPaged;
      } else if (queryResultData.uploadLinksForConnectedStoragePaged) {
        return queryResultData.uploadLinksForConnectedStoragePaged;
      }
    }
    return null;
  };

  const uploadLinksArray = getUploadLinksArray(uploadLinksQueryResult.data);

  React.useEffect(() => {
    if (
      uploadLinksQueryResult.data &&
      !uploadLinksQueryResult.loading &&
      !uploadLinksQueryResult.error
    ) {
      setAllItemsRetrieved(
        uploadLinksArray.length % PAGE_SIZE != 0 || uploadLinksArray.length == 0
      );
      setItemsListEmpty(uploadLinksArray.length == 0);
    }
  }, [uploadLinksQueryResult]);

  const fetchMoreItems = React.useCallback(() => {
    if (fetchMoreOngoing.current) {
      if (DEBUG) {
        console.log("fetchMoreItems already ongoing");
      }
      return;
    }
    fetchMoreOngoing.current = true;

    if (allItemsRetrieved) {
      if (DEBUG) {
        console.log("Already retrieved everything");
      }
      fetchMoreOngoing.current = false;
      return;
    }
    const nextPageToFetch = lastFetchedPage.current + 1;

    if (DEBUG) {
      console.log("Fetch more data for page " + nextPageToFetch);
    }
    uploadLinksQueryResult
      .fetchMore(getVariables(queryType, nextPageToFetch))
      .then((result) => {
        if (DEBUG) {
          console.log("Fetch more result %o", result);
        }
        const uploadLinksArray = getUploadLinksArray(result.data);

        if (result.data && uploadLinksArray && uploadLinksArray.length == 0) {
          setAllItemsRetrieved(true);
        }
        lastFetchedPage.current = nextPageToFetch;
        fetchMoreOngoing.current = false;
      })
      .catch((e) => {
        //setFetchOngoing(false)
        fetchMoreOngoing.current = false;
      });
    //}
  }, [
    uploadLinksQueryResult,
    uploadLinksQueryResult.data,
    allItemsRetrieved,
    fetchMoreOngoing.current,
    getVariables,
    queryType,
  ]);

  const handleOnScroll = React.useCallback(
    (e: any) => {
      const bottom =
        e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
      if (bottom) {
        fetchMoreItems();
      }
    },
    [fetchMoreItems]
  );

  React.useEffect(() => {
    if (uploadLinksQueryResult.data && uploadLinksArray) {
      if (uploadLinksArray.length == 0) {
        setEmptyResult(true);
      } else {
        setEmptyResult(false);
      }
    }
  }, [uploadLinksQueryResult.data]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        overflowY: "auto",
        height: "100%",
      }}
    >
      <Box
        onScroll={handleOnScroll}
        sx={{
          display: "flex",
          flex: "1",
          width: "100%",
          //  background: "yellow",
          height: "100%",
          overflowX: "hidden",
          overflowY: "auto",
          py: theme.spacing(1),
          paddingTop: theme.spacing(1),
        }}
      >
        {uploadLinksQueryResult.loading && (
          <Card
            sx={{
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "top",
              paddingTop: "10%",
            }}
          >
            <CircularProgress size={"96px"} />
          </Card>
        )}
        {!emptyResult && !uploadLinksQueryResult.loading && (
          <List
            sx={{
              width: "100%",
            }}
          >
            {uploadLinksQueryResult &&
              uploadLinksQueryResult.data &&
              uploadLinksArray &&
              uploadLinksArray.map((item: UploadLink, index: number) => {
                return (
                  <UploadLinkItem
                    key={item.id}
                    item={item}
                    onClick={(uploadLink: UploadLink) => {
                      navigate(
                        getUploadLinkDetailViewRelativeUrlFromId(uploadLink.id)
                      );
                    }}
                  />
                );
              })}
          </List>
        )}
        {emptyResult && (
          <Card
            sx={{
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "top",
              paddingTop: "10%",
            }}
          >
            <img
              src={LinkIllustration}
              style={{
                width: "156px",
                height: "156px",
              }}
            />
            <Typography
              sx={{
                marginTop: theme.spacing(1),
              }}
              variant="subtitle2"
            >
              {t("dashboard_last_upload_links_empty_title")}
            </Typography>
            <Button
              variant="contained"
              sx={{
                marginTop: theme.spacing(1),
              }}
              onClick={() => {
                setDisplayCreateNewLink(true);
              }}
            >
              + {t("create_action")}
            </Button>
          </Card>
        )}
      </Box>
      {displayCreateNewLink && (
        <CreateUploadLinkModal
          open={displayCreateNewLink}
          handleModalClose={() => {
            setDisplayCreateNewLink(false);
          }}
        />
      )}
    </Box>
  );
};

interface UploadLinkItemProps {
  item: UploadLink;
  onClick: (item: UploadLink) => void;
}

const UploadLinkItem = ({ item, onClick }: UploadLinkItemProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [itemHovered, setItemHovered] = useState(false);


  return (
    <ListItem
      key={item.id}
      sx={{
        width: "100%",
      }}
    >
      <Card
        key={item.id}
        sx={{
          boxShadow:
            "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px",
          borderRadius: "8px",
          width: "100%",
        }}
        onMouseEnter={() => {
          setItemHovered(true);
        }}
        onMouseLeave={() => {
          setItemHovered(false);
        }}
      >
        <CardActionArea
          sx={{
            //padding: "24px",
            px: theme.spacing(4),
            py: theme.spacing(1),
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            //justifyContent: "space",
            width: "100%",
            [theme.breakpoints.down("md")]: {
              px: theme.spacing(1),
            },
          }}
          onClick={() => {
            onClick(item);
          }}
        >
          <Box
            sx={{
              width: "10%",
              boxSizing: "border-box",
            }}
          >
            {getIconForStorageProvider(
              item.integrations.length > 0
                ? item.integrations[0].storageProvider
                : StorageProviderType.UPLOADLY_STORAGE
            )}
          </Box>
          <Typography
            sx={{
              flex: "1",
              boxSizing: "border-box",
              wordWrap: "break-word",
            }}
            variant="subtitle2"
          >
            {item.name}
          </Typography>
          {/*   <Typography
            sx={{
              flex: "1",
              boxSizing: "border-box",
              wordWrap: "break-word",
            }}
            variant="caption"
          >
            {t("created_at", { date: formatIso8601date(item.createdAt) })}
          </Typography> */}
          <Typography
            sx={{
              flex: "1",
              boxSizing: "border-box",
              wordWrap: "break-word",
              [theme.breakpoints.down("md")]: {
                display: "none",
              },
            }}
            variant="caption"
          >
            {t("created_at", {
              date: formatIso8601date(item.createdAt),
            })}
          </Typography>
          <Typography
            sx={{
              flex: "1",
              boxSizing: "border-box",
              wordWrap: "break-word",
              [theme.breakpoints.down("sm")]: {
                display: "none",
              },
            }}
            variant="caption"
          >
            {item.uploadsCount + " " + t("uploads")}
          </Typography>
          <Box
            sx={{
              flex: "1",
            }}
          >
            <Typography
              sx={{
                flex: "1",
                boxSizing: "border-box",
                wordWrap: "break-word",
                background: getLinkStatusColor(item.status),
                padding: "4px",
                borderRadius: "4px",
                color: "black",
              }}
              variant="caption"
            >
              {item.status == UploadLinkStatus.ACTIVE
                ? t("expires_in", {
                    expiry: getRemainingTime(item.expiryDate, t),
                  })
                : getLinkStatusString(item.status, t)}
            </Typography>
          </Box>{" "}
          <Box
            sx={{
              flex: "1",
              display: "flex",
              alignItems: "center",
              width: "100%",
            }}
          >
            {item.integrations.length > 0 && (
              <DestinationFolderBadge
                sx={{
                  alignItems: "center",
                  background: "red",
                }}
                destinationFolder={item.integrations[0].destinationFolder}
                storageProviderId={item.integrations[0].storageProvider}
              />
            )}
          </Box>
          <Box
            sx={{
              width: "5%",
              display: "flex",
              justifyContent: "end",
            }}
          >
            {itemHovered && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <CopyButtonToolTipped
                  onClick={() => {
                    navigator.clipboard.writeText(
                      getUploadLinkUrlFromToken(item.token)
                    );
                  }}
                />
                <Tooltip title={t("open_action")}>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      window.open(getUploadLinkUrlFromToken(item.token));
                    }}
                  >
                    <OpenInNewIcon
                      sx={{
                        width: "16px",
                        height: "16px",
                      }}
                    />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
          </Box>
        </CardActionArea>
      </Card>
    </ListItem>
  );
};

export default React.memo(UploadlyLinksContainer);

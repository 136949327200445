import * as React from "react";
import { Button, PaletteMode } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { ThemeProvider, createTheme, useTheme } from "@mui/material/styles";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import AutoAwesomeRoundedIcon from "@mui/icons-material/AutoAwesomeRounded";
import AppAppBar from "components/landing/AppAppBar";
import Hero from "components/landing/Hero";
import LogoCollection from "components/landing/LogoCollection";
import Highlights from "components/landing/Highlights";
import Pricing from "components/landing/Pricing";
import Features from "components/landing/Features";
import Testimonials from "components/landing/Testimonials";
import FAQ from "components/landing/FAQ";
import Footer from "components/landing/Footer";
import getLPTheme from "getLPTheme";
import UploadlyFullIcon from "res/icons/uploadly_full.png";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { LOGIN, SIGNUP } from "navigation/Constants";

interface ToggleCustomThemeProps {
  showCustomTheme: Boolean;
  toggleCustomTheme: () => void;
}

function ToggleCustomTheme({
  showCustomTheme,
  toggleCustomTheme,
}: ToggleCustomThemeProps) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100dvw",
        position: "fixed",
        bottom: 24,
      }}
    >
      <ToggleButtonGroup
        color="primary"
        exclusive
        value={showCustomTheme}
        onChange={toggleCustomTheme}
        aria-label="Platform"
        sx={{
          backgroundColor: "background.default",
          "& .Mui-selected": {
            pointerEvents: "none",
          },
        }}
      >
        <ToggleButton value>
          <AutoAwesomeRoundedIcon sx={{ fontSize: "20px", mr: 1 }} />
          Custom theme
        </ToggleButton>
        <ToggleButton value={false}>Material Design 2</ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
}

export default function LandingPage() {
  const theme = useTheme();
  const [mode, setMode] = React.useState<PaletteMode>("light");
  const { t } = useTranslation();
  const [showCustomTheme, setShowCustomTheme] = React.useState(true);
  const LPtheme = createTheme(getLPTheme(mode));
  const defaultTheme = createTheme({ palette: { mode } });
  const navigate = useNavigate();
  const toggleColorMode = () => {
    setMode((prev) => (prev === "dark" ? "light" : "dark"));
  };

  const toggleCustomTheme = () => {
    setShowCustomTheme((prev) => !prev);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "78px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          background: "aliceblue",
          px: theme.spacing(16),
         // boxShadow: "rgba(212, 211, 210, 0.55) 1.95px 1.95px 2.6px",
          [theme.breakpoints.down("sm")]: {
            px: theme.spacing(1),
          },
          [theme.breakpoints.down("md")]: {
            px: theme.spacing(1),
          },
        }}
      >
        <img src={UploadlyFullIcon} width={"156px"} />
        <Box>
          <Button
            onClick={() => {
              navigate(LOGIN);
            }}
            sx={{
              mx: theme.spacing(2),
            }}
          >
            {t("login_action")}
          </Button>
          <Button
            onClick={() => {
              navigate(SIGNUP);
            }}
            variant="contained"
          >
            {t("signup_action")}
          </Button>
        </Box>
      </Box>
      <Hero />
      <Box sx={{ bgcolor: "background.default" }}>
        {/*  <LogoCollection /> */}
        <Features />
        {/*  <Testimonials />
        <Divider />
        <Highlights /> */}
        <Pricing />
        <Divider />
        <FAQ />
        {/*         <Divider />
         */}{" "}
        <Footer />
      </Box>
    </Box>
   
  );
}

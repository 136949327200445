import React, { ReactNode, Suspense, useCallback } from "react";
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useNavigate,
  useRoutes,
} from "react-router-dom";
import {
  ROOT,
  LOGIN,
  SIGNUP,
  MAINBOARD,
  UPLOAD,
  STORAGES_FULL_PATH,
  UPLOADLY_LINKS_FULL_PATH,
  UPLOADLY_SHARE_LINKS_FULL_PATH,
  UPLOADLY_LINK_FULL_PATH,
  UPLOADED_FILES_FULL_PATH,
  CONNECT_NEW_STORAGES_FULL_PATH,
  CONNECTED_STORAGES_FULL_PATH,
  SETTINGS,
  SUBSCRIPTION_SETTINGS,
  GENERAL_SETTINGS,
  ACCOUNT_ACTIVATION,
  CONNECTED_STORAGE_FULL_PATH,
  REQUEST_RESET_PASSWORD,
  RESET_PASSWORD,
  PAYMENT,
  PAYMENT_WITH_ID,
  PRICING,
  PRIVACY_POLICY,
  TERMS_CONDITIONS,
  EMAIL_FORWARDING_FULL_PATH,
  SHARE_LINK_PAGE,
  UPLOADLY_SHARE_LINK_FULL_PATH,
} from "./Constants";

import { DEBUG } from "configuration";
import MainBoard from "pages/MainBoard";
import LoginPage from "pages/user/LoginPage";
import { useDispatch, useSelector } from "react-redux";
import PublicUpload from "pages/PublicUploadPage";
import LandingPage from "pages/LandingPage";
import IntegrationsContainer from "pages/mainboard/IntegrationsContainer";
import { Button } from "@mui/material";
import { LogoutApiResult, LogoutStatusCodes, logout } from "api/authApi";
import { logoutUser as logoutReducerAction } from "store/auth";
import UploadlyLinksContainer from "pages/mainboard/UploadlyLinksContainer";
import UploadlyLinkDetailContainer from "pages/mainboard/UploadlyLinkDetailContainer";
import UploadedFilesContainer from "pages/mainboard/UploadedFilesContainer";
import { RootState } from "store";
import Dashboard from "pages/mainboard/Dashboard";
import UserSettings from "pages/settings/UserSettings";
import General from "pages/settings/General";
import SubscriptionSettings from "pages/settings/SubscriptionSettings";
import ConnectedStoragesContainer from "pages/storages/ConnectedStoragesContainer";
import ConnectNewStorageContainer from "pages/storages/ConnectNewStorageContainer";
import SingupPage from "pages/user/SingupPage";
import AccountActivationPage from "pages/user/AccountActivationPage";
import ConnectedStorageDetails from "pages/storages/ConnectedStorageDetails";
import AllUploadlyLinksPage from "pages/mainboard/AllUploadlyLinksPage";
import AllUploadedItems from "pages/mainboard/AllUploadedItems";
import RequestResetPasswordPage from "pages/user/RequestResetPasswordPage";
import ResetPasswordPage from "pages/user/ResetPasswordPage";
import PaymentPage from "pages/payment/PaymentPage";
import PricingPage from "pages/PricingPage";
import PrivacyPolicy from "pages/Privacy/PrivacyPolicy";
import TermsConditions from "pages/Privacy/TermsConditions";
import { setPathAfterLogin } from "store/view";
import EmailForwardingContainer from "pages/mainboard/EmailForwardingContainer";
import ShareLinkPage from "pages/ShareLinkPage";
import AllShareLinksPage from "pages/sharelinks/AllShareLinksPage";
import ShareLinkDetailContainer from "pages/sharelinks/ShareLinkDetailContainer";

function MainRouterConfig() {
  React.useEffect(() => {
    // console.log("Rendering  MainRouterConfig  .....");
  }, []);
  return (
    <>
      <Routes>
        <Route
          path={ROOT}
          element={
            <ProtectedRoute path={ROOT}>
              <LandingPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={REQUEST_RESET_PASSWORD}
          element={
            <ProtectedRoute path={REQUEST_RESET_PASSWORD}>
              <RequestResetPasswordPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={RESET_PASSWORD}
          element={
            <ProtectedRoute path={RESET_PASSWORD}>
              <ResetPasswordPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={PRICING}
          element={
            <ProtectedRoute path={PRICING}>
              <PricingPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={PRIVACY_POLICY}
          element={
            <ProtectedRoute path={PRIVACY_POLICY}>
              <PrivacyPolicy />
            </ProtectedRoute>
          }
        />
        <Route
          path={TERMS_CONDITIONS}
          element={
            <ProtectedRoute path={TERMS_CONDITIONS}>
              <TermsConditions />
            </ProtectedRoute>
          }
        />
        <Route
          path={PAYMENT}
          element={
            <ProtectedRoute isPrivate path={PAYMENT}>
              <PaymentPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={PAYMENT_WITH_ID}
          element={
            <ProtectedRoute isPrivate path={PAYMENT_WITH_ID}>
              <PaymentPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={SETTINGS}
          element={
            <ProtectedRoute isPrivate path={SETTINGS}>
              <UserSettings>
                <Outlet />
              </UserSettings>
            </ProtectedRoute>
          }
        >
          <Route index element={<General />} />
          <Route
            path={SUBSCRIPTION_SETTINGS}
            element={<SubscriptionSettings />}
          />
          <Route path={GENERAL_SETTINGS} element={<General />} />
        </Route>
        <Route
          path={MAINBOARD}
          element={
            <ProtectedRoute isPrivate path={MAINBOARD}>
              <MainBoard>
                <Outlet />
              </MainBoard>
            </ProtectedRoute>
          }
        >
          <Route key={"index"} index element={<Dashboard />} />
          {/* 
          <Route
            key={STORAGES_FULL_PATH}
            path={STORAGES_FULL_PATH}
            element={<IntegrationsContainer />}
          /> */}

          <Route
            path={EMAIL_FORWARDING_FULL_PATH}
            element={<EmailForwardingContainer />}
          />
          <Route
            path={STORAGES_FULL_PATH}
            element={
              <IntegrationsContainer>
                <Outlet />
              </IntegrationsContainer>
            }
          >
            <Route
              key={CONNECTED_STORAGES_FULL_PATH}
              path={CONNECTED_STORAGES_FULL_PATH}
              element={<ConnectedStoragesContainer />}
            />
            <Route
              index
              key={CONNECT_NEW_STORAGES_FULL_PATH}
              path={CONNECT_NEW_STORAGES_FULL_PATH}
              element={<ConnectNewStorageContainer />}
            />
          </Route>

          <Route
            key={UPLOADLY_LINKS_FULL_PATH}
            path={UPLOADLY_LINKS_FULL_PATH}
            element={<AllUploadlyLinksPage />}
          />
          <Route
            key={UPLOADLY_SHARE_LINKS_FULL_PATH}
            path={UPLOADLY_SHARE_LINKS_FULL_PATH}
            element={<AllShareLinksPage />}
          />
          <Route
            key={UPLOADLY_SHARE_LINK_FULL_PATH}
            path={UPLOADLY_SHARE_LINK_FULL_PATH}
            element={<ShareLinkDetailContainer />}
          />
          <Route
            key={UPLOADLY_LINK_FULL_PATH}
            path={UPLOADLY_LINK_FULL_PATH}
            element={<UploadlyLinkDetailContainer />}
          />
          <Route
            key={CONNECTED_STORAGE_FULL_PATH}
            path={CONNECTED_STORAGE_FULL_PATH}
            element={<ConnectedStorageDetails />}
          />
          <Route
            key={UPLOADED_FILES_FULL_PATH}
            path={UPLOADED_FILES_FULL_PATH}
            element={<AllUploadedItems />}
          />
        </Route>
        <Route path={SHARE_LINK_PAGE} element={<ShareLinkPage />} />
        <Route path={UPLOAD} element={<PublicUpload />} />
        <Route
          path={LOGIN}
          element={
            <ProtectedRoute path={LOGIN}>
              <LoginPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={ACCOUNT_ACTIVATION}
          element={
            <ProtectedRoute path={ACCOUNT_ACTIVATION}>
              <AccountActivationPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={SIGNUP}
          element={
            <ProtectedRoute path={SIGNUP}>
              <SingupPage />
            </ProtectedRoute>
          }
        />
      </Routes>
    </>
  );
}

interface ProtectedRouteProps {
  path: string;
  isPrivate?: boolean;
  children: ReactNode;
}
const ProtectedRoute = ({ isPrivate, path, children }: ProtectedRouteProps) => {
  const userLoggedIn = useSelector(
    (state: RootState) => state.auth.isUserLoggedIn
  );
  const afterLoginPath = useSelector(
    (state: RootState) => state.view.pathAfterLogin
  );
  // const navigate = useNavigate();

  /*   const afterLoginPath = useSelector(
    (state: RootState) => state.view.pathAfterLogin
  ); */
  const dispatch = useDispatch();
  if (DEBUG) {
    console.log("ProtectedRoute: userLoggedIn ? " + userLoggedIn);
    console.log("ProtectedRoute: navigate to " + path);
  }
  /* 
  if (userLoggedIn && afterLoginPath){
    navigate(afterLoginPath);
    dispatch(setPathAfterLogin(null))
    return;
  }
 */
  const currentUrl = window.location.href;
  const searchParams = new URL(currentUrl).searchParams;
  const code = searchParams.get("code");
  const error = searchParams.get("error");
  if ((code || error) && path == ROOT) {
    //This is done to avoid redirections during auth flow, and be able to retrieve the auth code returned through redirection url to uploadly
    return children;
  }
  console.log("ProtectedRoute: going through here userLoggedIn=" + userLoggedIn);

  if (!userLoggedIn && isPrivate) {
    console.log("ProtectedRoute: path dispatch is " + window.location.pathname);
    dispatch(setPathAfterLogin(window.location.pathname));
    return <Navigate to={LOGIN} replace />;
  }
  if (userLoggedIn && (path == ROOT || path == LOGIN || path == SIGNUP)) {
    console.log("ProtectedRoute: afterLoginPath = " + afterLoginPath);

    if (path == LOGIN && afterLoginPath && afterLoginPath != LOGIN) {
      return <Navigate to={afterLoginPath} replace />;
    }
    console.log("ProtectedRoute: path dispatch to mainboard");
    return <Navigate to={MAINBOARD} replace />;
  }
  console.log("ProtectedRoute: path dispatch to path " + path);
  return children;
};

/* const Blue = () => {
  const dispatch = useDispatch();

  const handleLogout = useCallback(() => {
    logout().then((result: LogoutApiResult) => {
      if (result.statusCode == LogoutStatusCodes.SUCCESS) {
        //setOpenDrawer(false);
        dispatch(logoutReducerAction());
      }
    });
  }, []);
  return (
    <div style={{ width: "100vw", height: "100vh", background: "blue" }}>
      <Button
        variant="contained"
        sx={{
          width: "auto",
        }}
        fullWidth={false}
        onClick={handleLogout}
      >
        Logout
      </Button>
    </div>
  );
};
const Red = () => {
  return (
    <div style={{ width: "100vw", height: "100vh", background: "red" }}></div>
  );
}; */

export default React.memo(MainRouterConfig);

import { SubscriptionPlanIds } from "models/user";


export function getYearlyPricePerMonthInLocalCurrency(currencyIso4217: string | undefined | null, planId: number) {
    if (planId == SubscriptionPlanIds.FREE){
        return "0"
    }
    switch (currencyIso4217) {
        case "EUR":
            return planId == SubscriptionPlanIds.STANDARD ?  "7" : "14";
        case "USD":
        default:
            return planId == SubscriptionPlanIds.STANDARD ?  "7" : "14";
    }
}
export function getPriceInLocalCurrency(currencyIso4217: string | undefined | null, planId: number, isYearly: boolean) {
    if (planId == SubscriptionPlanIds.FREE){
        return "0"
    }
    switch (currencyIso4217) {
        case "EUR":
            if (isYearly){
                return planId == SubscriptionPlanIds.STANDARD ?  "84" : "168";
            } else {
                return planId == SubscriptionPlanIds.STANDARD ?  "9" : "18";
            }
        case "USD":
        default:
            if (isYearly){
                return planId == SubscriptionPlanIds.STANDARD ?  "84" : "168";
            } else {
                return planId == SubscriptionPlanIds.STANDARD ?  "9" : "18";
            }
    }
}

export function getCurrencySymbol(currencyIso4217: string | undefined | null): string {
    switch (currencyIso4217) {
        case "EUR":
            return "€";
        case "USD":
            return "$";
        default:
            return "$"
    }
}
import {
  Box,
  CircularProgress,
  IconButton,
  Link,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import {
  StorageProviderType,
  UploadLink,
  UploadLinkStatus,
} from "models/uploadly";
import React, { useCallback, useEffect, useRef, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import {
  retrieveShareLinkForIdGql,
  retrieveUploadLinkForIdGql,
} from "graphql/queries";
import { useTranslation } from "react-i18next";
import {
  bytesToGigabytes,
  formatIso8601date,
  getRemainingTime,
  getSizeInHumeanReadableFormat,
} from "shared/dataUtils";
import { UPLOADLY_LINKS_FULL_PATH } from "navigation/Constants";
import {
  getPublicShareLinkFromToken,
  getUploadLinkUrlFromToken,
} from "configuration";
import CopyButtonToolTipped from "components/ui/CopyButtonToolTipped";
import {
  getIconForStorageProvider,
  getStorageProviderName,
} from "pages/storages/storageProviderIds";
import DestinationFolderBadge from "components/ui/DestinationFolderBadge";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import CircleIcon from "@mui/icons-material/Circle";
import { QRCodeCanvas, QRCodeSVG } from "qrcode.react";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import PublicUploadLinkPreview from "pages/PublicUploadLinkPreviewEditor";
import {
  getLinkStatusColor,
  getLinkStatusDotColor,
  getLinkStatusString,
} from "shared/utility";
import { ShareLink, ShareLinkStatus } from "models/shareLink";
import ShareLinkFileItemsContainer from "./ShareLinkFileItemsContainer";
import ShareLinkSettingsContainer from "./ShareLinkSettingsContainer";

const TAB_SHARED_FILES_LIST = 0;
const TAB_CONNECTED_STORAGE = 1;
const TAB_SETTINGS = 2;
const TAB_LAYOUT = 3;

const ShareLinkDetailContainer = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const [tabValue, setTabValue] = React.useState(TAB_SHARED_FILES_LIST);

  const shareLinkResult = useQuery(retrieveShareLinkForIdGql, {
    variables: {
      id: params.id,
    },
  });

  const containerRef = useRef(null);
  const childRef = useRef(null);
  const shareLink: ShareLink =
    shareLinkResult.data && shareLinkResult.data.shareLink
      ? shareLinkResult.data.shareLink
      : null;

  const getShareLinkTitle = useCallback(() => {
    if (!shareLink) {
      return "";
    }
    if (shareLink && shareLink.title && shareLink.title.length > 0) {
      return shareLink.title;
    } else {
      return shareLink.filesCount + " " + t("files");
    }
  }, [shareLink]);

  const qrRef = useRef<any>();

  const downloadQRCode = useCallback(() => {
    const canvas = qrRef.current.querySelector("canvas");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = shareLink.title + "-qrcode.png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }, [qrRef.current, shareLink]);

  /* useEffect(() => {
      console.log(
        "entry change : use effect container" +
          containerRef.current +
          " child ref " +
          childRef.current
      );
      const observerCallback = (entries: any) => {
        entries.forEach((entry: any) => {
          // setIsVisible(entry.isIntersecting);
          console.log("entry change here  %o", entry);
           setIsIntersecting(entry.isIntersecting)
        });
      };
  
      const observer = new IntersectionObserver(observerCallback, {
        root: null,
        rootMargin: "0px",
        threshold: 0,
      });
  
      const target = childRef.current;
      if (target) {
        observer.observe(target);
      }
  
      // Cleanup function to unobserve the target element
      return () => {
        if (target) {
          observer.unobserve(target);
        }
      };
    }, [uploadLinkResult.data]);
   */

  if (
    !shareLinkResult.loading &&
    !shareLinkResult.error &&
    shareLinkResult.data
  ) {
    return (
      <Box
        ref={containerRef}
        sx={{
          px: theme.spacing(8),
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          [theme.breakpoints.down("md")]: {
            px: theme.spacing(1),
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <IconButton
            onClick={() => {
              navigate(-1);
            }}
          >
            <ArrowBackIcon
              sx={{
                height: "32px",
                width: "32px",
              }}
            />
          </IconButton>

          <Typography
            variant="h3"
            sx={{
              marginLeft: theme.spacing(3),
            }}
          >
            {getShareLinkTitle()}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            [theme.breakpoints.down("md")]: {
              flexDirection: "column",
              alignItems: "start",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {/*  <Typography
              variant="subtitle2"
              sx={{
                color: "gray",
              }}
            >
              {getIconForStorageProvider(
                uploadLink.integrations.length > 0
                  ? uploadLink.integrations[0].storageProvider
                  : StorageProviderType.UPLOADLY_STORAGE,
                {
                  height: "24px",
                  width: "24px",
                }
              )}
            </Typography> */}
            {/*  <Typography
              variant="subtitle2"
              sx={{
                color: "gray",
                marginLeft: theme.spacing(1),
              }}
            >
              {getStorageProviderName(
                uploadLink.integrations.length > 0
                  ? uploadLink.integrations[0].storageProvider
                  : StorageProviderType.UPLOADLY_STORAGE
              )}
            </Typography>
            {uploadLink.integrations.length > 0 && (
              <DestinationFolderBadge
                destinationFolder={uploadLink.integrations[0].destinationFolder}
                storageProviderId={uploadLink.integrations[0].storageProvider}
                sx={{
                  marginLeft: theme.spacing(2),
                }}
              />
            )} */}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{
                color: "gray",
                marginLeft: theme.spacing(0.5),
              }}
            >
              {shareLink.filesCount +
                " " +
                (shareLink.filesCount > 1
                  ? t("files_plural")
                  : t("file_singular"))}
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{
                marginLeft: theme.spacing(1),
                color: "gray",
              }}
            >
              •
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{
                color: "gray",
                mx: theme.spacing(1),
              }}
            >
              {getSizeInHumeanReadableFormat(shareLink.filesTotalSize)}
            </Typography>

            <Typography
              variant="subtitle2"
              sx={{
                color: "gray",
              }}
            >
              •
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{
                marginLeft: theme.spacing(1),
                color: "gray",
              }}
            >
              {t("created_at", {
                date: formatIso8601date(shareLink.createdAt),
              })}
            </Typography>
          </Box>
          {/* <Typography
              variant="subtitle2"
              sx={{
                marginLeft: theme.spacing(1),
                color: "gray",
              }}
            >
              {bytesToGigabytes(uploadLink.totalUploadsSize) +
                "/" +
                getSizeInHumeanReadableFormat(uploadLink.maxTotalUploadsSize)}
            </Typography> */}
        </Box>

        <Box
          ref={childRef}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            [theme.breakpoints.down("md")]: {
              flexDirection: "column",
              alignItems: "start",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <CircleIcon
              sx={{
                width: "12px",
                height: "12px",
                color: getLinkStatusDotColor(shareLink.status),
              }}
            />
            <Typography
              variant="subtitle2"
              sx={{
                marginLeft: theme.spacing(1),
                color: "gray",
              }}
            >
              {getLinkStatusString(shareLink.status, t)}
            </Typography>
            {shareLink.status != ShareLinkStatus.EXPIRED && (
              <>
                <Typography
                  variant="subtitle2"
                  sx={{
                    marginLeft: theme.spacing(1),
                    color: "gray",
                  }}
                >
                  •
                </Typography>

                <Typography
                  variant="subtitle2"
                  sx={{
                    marginLeft: theme.spacing(1),
                    color: "gray",
                  }}
                >
                  {t("expires_in", {
                    expiry: getRemainingTime(shareLink.expiryDate, t),
                  })}
                </Typography>
              </>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              [theme.breakpoints.down("md")]: {
                mt: theme.spacing(1),
              },
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{
                marginLeft: theme.spacing(1),
                color: "gray",
                [theme.breakpoints.down("md")]: {
                  display: "none",
                },
              }}
            >
              •
            </Typography>
            <Link
              component="button"
              variant="body2"
              sx={{
                textAlign: "start",
                display: "flex",
                wordBreak: "break-word",
                marginLeft: theme.spacing(1),
              }}
              onClick={() => {
                window.open(
                  getPublicShareLinkFromToken(shareLink.token),
                  "_blank"
                );
              }}
            >
              {getPublicShareLinkFromToken(shareLink.token)}
            </Link>
            <CopyButtonToolTipped
              sx={{
                marginLeft: theme.spacing(1),
              }}
              onClick={() => {
                navigator.clipboard.writeText(
                  getPublicShareLinkFromToken(shareLink.token)
                );
              }}
            />
            <Tooltip title={t("download_qr_code_title")}>
              <IconButton onClick={downloadQRCode}>
                <QrCode2Icon />
              </IconButton>
            </Tooltip>
          </Box>
          <div style={{ display: "none" }} ref={qrRef}>
            <QRCodeCanvas
              value={getPublicShareLinkFromToken(shareLink.token)}
              size={600}
            />
          </div>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginTop: theme.spacing(1),
            }}
          ></Box>
        </Box>

        <Tabs
          sx={
            {
              /*   position: "sticky",
              zIndex: 10,
              top: "10px" */
            }
          }
          value={tabValue}
          onChange={(event: React.SyntheticEvent, newValue: any) => {
            // console.log("Got a change here " + JSON.stringify(newValue));
            setTabValue(newValue);
          }}
          variant="scrollable"
          scrollButtons={false}
          aria-label="scrollable prevent tabs example"
        >
          <Tab value={TAB_SHARED_FILES_LIST} label={t("files_title")} />
          <Tab value={TAB_SETTINGS} label={t("upload_link_settings_title")} />
        </Tabs>
        {tabValue == TAB_SHARED_FILES_LIST && params.id && shareLink && (
          <ShareLinkFileItemsContainer
            shareLinkId={params.id}
            shareLinkExpired={shareLink.status == ShareLinkStatus.EXPIRED}
          />
        )}
        {tabValue == TAB_SETTINGS && shareLink && (
          <ShareLinkSettingsContainer shareLink={shareLink} />
        )}
        {/*   {tabValue == TAB_LAYOUT && uploadLink && (
          <PublicUploadLinkPreview uploadLink={uploadLink} />
        )} */}
      </Box>
    );
  } else {
    return <></>;
  }
};

export default React.memo(ShareLinkDetailContainer);

import { ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import React, { useCallback } from "react";
import DownloadIcon from "@mui/icons-material/Download";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useTranslation } from "react-i18next";
import { UploadItem } from "models/uploadly";
import {
  DEBUG,
  LAST_UPLOAD_ITEMS_RETRIEVED_IN_DASHBOARD,
  getInternalFileAccessLink,
  getInternalFileDownloadLink,
} from "configuration";
import { DeleteOutline } from "@mui/icons-material";
import SimpleConfirmationDialog from "components/ui/dialogs/SimpleConfirmationDialog";
import { useMutation } from "@apollo/client";
import {
  deleteEmailUploadItemGql,
  deleteUploadItemGql,
} from "graphql/mutations";
import { useDispatch } from "react-redux";
import { showSnackbar } from "store/view";
import { checkIfUploadItemIsPresentInLastUploadItems } from "graphql/gqlcachehelper";
import { retrieveLastNUploadItemsGql } from "graphql/queries";

interface UploadItemDetailMenuProps {
  uploadItem: UploadItem;
  open: boolean;
  anchorElement: any;
  isEmailUploadItem?: boolean;
  onClose: () => void;
  closeParent?: () => void;
}

const DOWNLOAD_ID = 0;
const DELETE_ID = 1;

const UploadItemDetailMenu = ({
  uploadItem,
  open,
  onClose,
  anchorElement,
  closeParent,
  isEmailUploadItem = false,
}: UploadItemDetailMenuProps) => {
  const { t } = useTranslation();
  //const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  // const open = Boolean(anchorEl);
  //console.log("isEmailUploadItem" + isEmailUploadItem)
  const dispatch = useDispatch();
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const [deleteUploadItem, deleteUploadItemResult] = useMutation(
    isEmailUploadItem ? deleteEmailUploadItemGql : deleteUploadItemGql,
    {
      variables: {
        id: uploadItem.id,
      },
    }
  );
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = React.useState(
    false
  );
  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLElement>,
    id: number
  ) => {
    switch (id) {
      case DOWNLOAD_ID:
        handleDownloadFile();
        break;
      case DELETE_ID:
        setOpenDeleteConfirmation(true);
        break;
      default:
        console.log("action not supported!");
    }
    //  setSelectedIndex(index);
    //  setAnchorEl(null);
  };

  const getMenuOptions = useCallback(() => {
    const options = [
      {
        title: t("download_action"),
        id: DOWNLOAD_ID,
        icon: <DownloadIcon />,
      },
      {
        title: t("delete_action"),
        id: DELETE_ID,
        icon: <DeleteOutline />,
      },
      /*  {
        title: t("send_to_storage_action"),
        icon: <CloudUploadIcon />,
      }, */
      /*  {
        title: "envoyer par email",
        icon: <CloudUploadIcon />,
      },
      {
        title: "envoyer sur un lien uploadly",
        icon: <CloudUploadIcon />,
      }, */
    ];
    return options;
  }, []);

  const handleDeleteItem = useCallback(() => {
    setOpenDeleteConfirmation(false);

    dispatch(
      showSnackbar({
        message: t("delete_ongoing_message"),
        loading: true,
      })
    );
    const isFound = checkIfUploadItemIsPresentInLastUploadItems(
      deleteUploadItemResult.client.cache,
      uploadItem.id
    );
    deleteUploadItem({
      refetchQueries: [
        {
          query: isFound ? retrieveLastNUploadItemsGql : (undefined as any),
          variables: {
            n: LAST_UPLOAD_ITEMS_RETRIEVED_IN_DASHBOARD,
          },
        },
      ],
    })
      .then((result) => {
        console.log("Got deletion result %o", result);
        if (
          (result.data.deleteUploadItem && result.data.deleteUploadItem.success) ||
          (result.data.deleteEmailUploadItem && result.data.deleteEmailUploadItem.success)
        ) {
          dispatch(
            showSnackbar({
              message: t("delete_confirmed_message"),
              loading: false,
            })
          );
          closeParent && closeParent();
        } else {
          if (DEBUG) console.log("Got a result with an error ");

          dispatch(
            showSnackbar({
              message: t("deletion_failed_msg"),
              loading: false,
            })
          );
        }
      })
      .catch((e) => {
        if (DEBUG) console.log("Caught an exception here " + e);
        dispatch(
          showSnackbar({
            message: t("deletion_failed_msg"),
            loading: false,
          })
        );
      });
  }, [deleteUploadItem, uploadItem]);

  const handleDownloadFile = useCallback(() => {
    window.open(
      getInternalFileDownloadLink(uploadItem.internalFileUpload.id),
      "_self"
    );
  }, [uploadItem]);

  return (
    <>
      <Menu
        id="lock-menu"
        anchorEl={anchorElement}
        open={open}
        onClose={onClose}
        MenuListProps={{
          "aria-labelledby": "lock-button",
          role: "listbox",
        }}
      >
        {getMenuOptions().map((option, index) => (
          <MenuItem
            key={option.title}
            onClick={(event) => handleMenuItemClick(event, option.id)}
          >
            <ListItemIcon>{option.icon}</ListItemIcon>
            <ListItemText> {option.title}</ListItemText>
          </MenuItem>
        ))}

        <SimpleConfirmationDialog
          title={t("delete_confirmation_title")}
          description={t("delete_confirmation_description")}
          open={openDeleteConfirmation}
          handleModalClose={() => {
            setOpenDeleteConfirmation(false);
          }}
          handleOk={handleDeleteItem}
        />
      </Menu>
    </>
  );
};

export default React.memo(UploadItemDetailMenu);

import {
  Box,
  Button,
  Card,
  CardActionArea,
  Checkbox,
  Fade,
  Grid,
  IconButton,
  LinearProgress,
  Modal,
  Tooltip,
  Typography,
} from "@mui/material";
import { Theme, useTheme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import VerticalAlignTopIcon from "@mui/icons-material/VerticalAlignTop";
import { LoadingButton } from "@mui/lab";
import { getIconForStorageProvider } from "pages/storages/storageProviderIds";
import {
  DEBUG,
  MAX_UPLOAD_LINKS_FREE_PLAN,
  MAX_UPLOAD_LINKS_STANDARD_PLAN,
  getSharedFilePreview,
} from "configuration";
import {
  retrieveConnectedStoragesPagedGql,
  retrieveFilesForShareLinkGql,
} from "graphql/queries";
import DestinationFolderBadge from "components/ui/DestinationFolderBadge";
import {
  connectCloudToEmailGql,
  createUploadLinkGql,
  saveSharedLinkFilesToCloudGql,
} from "graphql/mutations";
import {
  addUploadLinkToCacheIfExists,
  uploadLastNUploadLinksItemsCache,
} from "graphql/gqlcachehelper";
import { ConnectedStorage, StorageProviderType } from "models/uploadly";
import { showSnackbar, showUpgradePlanDialog } from "store/view";
import { ShareLinkFile } from "models/shareLink";
import { getSizeInHumeanReadableFormat } from "shared/dataUtils";

import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import FolderZipOutlinedIcon from "@mui/icons-material/FolderZipOutlined";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import AudiotrackOutlinedIcon from "@mui/icons-material/AudiotrackOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import DownloadForOfflineOutlinedIcon from "@mui/icons-material/DownloadForOfflineOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import animationData from "res/animations/file_upload_animation.json";
import Lottie from "lottie-react";
import uploadCompleteAnimationData from "res/animations/complete_animation.json";

const MAX_UPLOAD_LINKS_COUNT_REACHED = 1;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      borderRadius: "25px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      position: "relative",
      width: "75%",
      marginTop: "24px",
      borderRadius: "12px",
      display: "flex",
      flexDirection: "column",
      height: "90%",
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      [theme.breakpoints.down("md")]: {
        width: "90%",
        px: 0,
      },
      /* [theme.breakpoints.down("xl")]: {
        width: "70%",
        px: 0,
      }, */
    },
    inputLabel: {
      fontSize: "1.9rem",
      padding: "16px",
      overflow: "hidden",
      "& .MuiInput-root": {
        height: "70%",
      },
      "& .MuiInput-input .MuiInputLabel-root": {
        fontSize: "large",
      },
      "&:hover .MuiInput-input .MuiInputBase-input": {
        outline: "none",
      },
      "&:focus .MuiInput-input": {
        outline: "none",
      },
    },
    annotationTextField: {
      fontSize: "1.9rem",
    },

    buttonContainer: {
      display: "flex",
      justifyContent: "end",
      paddingBottom: "24px",
    },
    applyButton: {
      marginEnd: "12px",
      marginStart: "12px",
      alignItems: "center",
      textTransform: "capitalize",
    },
  })
);

interface SaveSharedFileToCloudProps {
  open: boolean;
  shareLinkToken: string;
  handleModalClose: () => void;
}
const PAGE_SIZE = 20;

const getIconForFileType = (fileType: string) => {
  if (fileType.startsWith("image/")) {
    return <ImageOutlinedIcon />;
  } else if (fileType.startsWith("video/")) {
    return <VideocamOutlinedIcon />;
  } else if (fileType.startsWith("application/pdf")) {
    return <PictureAsPdfOutlinedIcon />;
  } else if (fileType.startsWith("application/zip")) {
    return <FolderZipOutlinedIcon />;
  } else if (fileType.startsWith("audio/")) {
    return <AudiotrackOutlinedIcon />;
  } else {
    return <InsertDriveFileOutlinedIcon />;
  }
};

const SaveSharedFileToCloud = ({
  open,
  shareLinkToken,
  handleModalClose,
}: SaveSharedFileToCloudProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.auth.user);

  const [selectedStorageId, setselectedStorageId] = React.useState<
    string | null
  >(null);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={() => handleModalClose()}
      closeAfterTransition
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box
          className={classes.paper}
          sx={{
            px: theme.spacing(2),
            justifyContent: "center",
            overflowY: "hidden",

            /* 
                [theme.breakpoints.down("sm")]: {
                  width: "100%",
                  px: 0,
                }, */
            transition: theme.transitions.create("all", {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
          }}
        >
          {!selectedStorageId && (
            <SelectCloudStorageContainer
              onStorageSelected={(storageId: string | null) => {
                setselectedStorageId(storageId);
              }}
              handleModalClosed={handleModalClose}
            />
          )}
          {selectedStorageId && (
            <SelectFilesToSaveToCloudContainer
              shareLinkToken={shareLinkToken}
              selectedStorageId={selectedStorageId}
              handleModalClose={handleModalClose}
            />
          )}
          {/*  <SelectFilesToSaveToCloudContainer
            shareLinkToken={shareLinkToken}
            selectedStorageId={selectedStorageId!!}
            handleModalClose={handleModalClose}
          /> */}
        </Box>
      </Fade>
    </Modal>
  );
};

const FilesLoadingView = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <LinearProgress />
      <Lottie
        style={{ height: "auto", width: "45%" }}
        animationData={animationData}
        loop={true}
        autoplay
      />
      <Typography
        variant="h4"
        sx={{
          marginBottom: theme.spacing(1),
        }}
      >
        {t("operation_in_progress_message")}
      </Typography>
    </Box>
  );
};

interface SomeFilesUploadFaledViewProps {
  sharedFiles: ShareLinkFile[];
  handleModalClose: () => void;
}

const SomeFilesUploadFailedView = ({
  sharedFiles,
  handleModalClose,
}: SomeFilesUploadFaledViewProps) => {
  const theme = useTheme();
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        px: theme.spacing(2),
        justifyContent: "space-between",
        overflowY: "hidden",
        transition: theme.transitions.create("all", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          marginTop: theme.spacing(3),
          marginLeft: theme.spacing(2),
        }}
      >
        <Typography variant="h5">
          ❌ {" " + t("some_files_upload_failed")}
        </Typography>
        <Grid
          container
          spacing={1}
          sx={{
            marginTop: theme.spacing(3),
            overflowY: "auto",
            display: "flex",
            justifyContent: "start",
            // justifyContent:"center"
            [theme.breakpoints.down("md")]: {
              px: theme.spacing(4),
              height: "100%",
            },
          }}
        >
          {sharedFiles.map((shareLinkFile: ShareLinkFile) => {
            return (
              <>
                <Grid
                  key={shareLinkFile.id}
                  sx={{
                    width: "100%",
                    alignItems: "center",
                    display: "flex",
                    py: theme.spacing(0.4),
                    [theme.breakpoints.down("sm")]: {
                      justifyContent: "center",
                      alignItems: "center",
                    },
                  }}
                  item
                  xs={6}
                  sm={3}
                  md={3}
                >
                  <Card
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      height: "100%",
                      boxShadow:
                        "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px",
                      borderRadius: "8px",
                      my: theme.spacing(1),
                      width: "100%",
                      overflow: "hidden",
                    }}
                  >
                    {shareLinkFile.hasPreview ? (
                      <img
                        style={{
                          objectFit: "cover",
                        }}
                        height="100px"
                        width="100%"
                        src={getSharedFilePreview(shareLinkFile.id)}
                      />
                    ) : (
                      <Box
                        sx={{
                          height: "100px",
                          display: "flex",
                          flexDirection: "center",
                          justifyContent: "center",
                          alignItems: "center",
                          background: theme.palette.primary.main,
                        }}
                      >
                        {getIconForFileType(shareLinkFile.fileType)}
                      </Box>
                    )}
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        // marginLeft: theme.spacing(1),
                        width: "100%",
                        px: theme.spacing(0.5),
                        py: theme.spacing(1),
                        overflow: "hidden",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                          overflow: "hidden",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: ".9rem",
                            fontWeight: "bold",
                            display: "-webkit-box",
                            wordWrap: "break-word",
                            textOverflow: "ellipsis",
                            WebkitBoxOrient: "vertical",
                            WebkitLineClamp: 2,
                            overflow: "hidden",
                            maxLines: 2,
                          }}
                        >
                          {shareLinkFile.fileName}
                        </Typography>
                        <Typography variant="caption">
                          {getSizeInHumeanReadableFormat(
                            shareLinkFile.fileSize
                          )}
                          {" • " + shareLinkFile.fileType}
                        </Typography>
                      </Box>
                    </Box>
                  </Card>
                </Grid>
              </>
            );
          })}
        </Grid>
      </Box>
      <div className={classes.buttonContainer}>
        <Button
          sx={{
            marginRight: theme.spacing(2),
          }}
          onClick={handleModalClose}
        >
          {t("ok")}
        </Button>
      </div>
    </Box>
  );
};

interface SelectCloudStorageContainerProps {
  onStorageSelected: (storageId: string | null) => void;
  handleModalClosed: () => void;
}

const SelectCloudStorageContainer = ({
  onStorageSelected,
  handleModalClosed,
}: SelectCloudStorageContainerProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const fetchMoreOngoing = React.useRef(false);
  const [connectCloudStorage, createUploadLinkResult] = useMutation(
    connectCloudToEmailGql
  );
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.auth.user);

  const [allItemsRetrieved, setAllItemsRetrieved] = React.useState(false);
  const [itemsListEmpty, setItemsListEmpty] = React.useState(false);

  const [selectedStorageId, setselectedStorageId] = React.useState<
    string | null
  >(null);
  const lastFetchedPage = React.useRef(1);

  const storagesQueryResult = useQuery(retrieveConnectedStoragesPagedGql, {
    variables: {
      page: 1,
      pageSize: PAGE_SIZE,
    },
  });

  React.useEffect(() => {
    if (
      storagesQueryResult.data &&
      !storagesQueryResult.loading &&
      !storagesQueryResult.error
    ) {
      setAllItemsRetrieved(
        storagesQueryResult.data.allConnectedStoragesPaged.length % PAGE_SIZE !=
          0 || storagesQueryResult.data.allConnectedStoragesPaged.length == 0
      );
      setItemsListEmpty(
        storagesQueryResult.data.allConnectedStoragesPaged.length == 0
      );
    }
  }, [storagesQueryResult]);

  const fetchMoreItems = React.useCallback(() => {
    if (fetchMoreOngoing.current) {
      if (DEBUG) {
        console.log("fetchMoreItems already ongoing");
      }
      return;
    }
    fetchMoreOngoing.current = true;

    if (allItemsRetrieved) {
      if (DEBUG) {
        console.log("Already retrieved everything");
      }
      fetchMoreOngoing.current = false;
      return;
    }
    const nextPageToFetch = lastFetchedPage.current + 1;

    if (DEBUG) {
      console.log("Fetch more data for page " + nextPageToFetch);
    }
    storagesQueryResult
      .fetchMore({
        variables: {
          page: nextPageToFetch,
          pageSize: PAGE_SIZE,
        },
      })
      .then((result) => {
        if (DEBUG) {
          console.log("Fetch more result %o", result);
        }
        if (
          result.data &&
          result.data.allConnectedStoragesPaged &&
          result.data.allConnectedStoragesPaged.length == 0
        ) {
          setAllItemsRetrieved(true);
        }
        lastFetchedPage.current = nextPageToFetch;
        fetchMoreOngoing.current = false;
      })
      .catch((e) => {
        //setFetchOngoing(false)
        fetchMoreOngoing.current = false;
      });
    //}
  }, [
    storagesQueryResult,
    storagesQueryResult.data,
    allItemsRetrieved,
    fetchMoreOngoing.current,
  ]);

  const handleOnScroll = React.useCallback(
    (e: any) => {
      const bottom =
        e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
      if (bottom) {
        fetchMoreItems();
      }
    },
    [fetchMoreItems]
  );
  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "space-between",
        overflowY: "hidden",

        /* 
          [theme.breakpoints.down("sm")]: {
            width: "100%",
            px: 0,
          }, */
        transition: theme.transitions.create("all", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          overflowY: "hidden",
          px: theme.spacing(2),
          width: "100%",
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{
            marginTop: theme.spacing(3),
            marginLeft: theme.spacing(2),
            overflowY: "hidden",
            color: theme.palette.primary.main,
            width: "100%",
          }}
        >
          {t("save_to_my_cloud_action")}
        </Typography>

        <Typography
          variant="caption"
          sx={{
            marginLeft: theme.spacing(2),
            marginBottom: theme.spacing(1),
            overflowY: "hidden",
            width: "100%",
          }}
        >
          {t("save_to_my_cloud_action_description")}
        </Typography>

        <Box
          onScroll={handleOnScroll}
          sx={{
            flex: "1",
            width: "100%",
            "&::-webkit-scrollbar": {
              width: 3,
            },
            "&::-webkit-scrollbar-track": {
              boxShadow: `inset 0 0 6px #faf6e9`,
              borderRadius: "4px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: theme.palette.primary.main,
              borderRadius: "4px",
            },
            //  background: "yellow",
            overflowY: "auto",
            paddingTop: theme.spacing(1),
            px: theme.spacing(2),
          }}
        >
          <Grid
            container
            spacing={2}
            sx={{
              overflowY: "auto",
            }}
          >
            {storagesQueryResult &&
              storagesQueryResult.data &&
              storagesQueryResult.data.allConnectedStoragesPaged &&
              storagesQueryResult.data.allConnectedStoragesPaged.map(
                (item: ConnectedStorage, index: number) => {
                  return (
                    <Grid item key={item.id} xs={12} sm={6} md={4}>
                      <Card
                        sx={{
                          height: "100%",
                          boxShadow:
                            "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px",
                          borderRadius: "8px",
                          border:
                            selectedStorageId == item.id
                              ? `2px solid ${theme.palette.primary.main}`
                              : "none",
                        }}
                        onClick={() => {
                          setselectedStorageId(item.id);
                        }}
                      >
                        <CardActionArea
                          sx={{
                            height: "100%",
                            //padding: "24px",
                            py: theme.spacing(1),
                            px: theme.spacing(2),
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            alignItems: "start",
                            background: "aliceblue",
                            width: "100%",
                          }}
                          onClick={() => {
                            // handleIntegrationClicked(item.id);
                          }}
                        >
                          <Box
                            sx={{
                              boxSizing: "border-box",
                              display: "flex",
                              alignItems: "start",
                            }}
                          >
                            {getIconForStorageProvider(item.storageProvider)}
                          </Box>
                          <Typography
                            sx={{
                              wordWrap: "break-word",
                              textOverflow: "ellipsis",
                              fontWeight: "bold",
                              py: theme.spacing(1),
                            }}
                            variant="caption"
                          >
                            {item.accountEmail}
                          </Typography>
                          <Box
                            sx={{
                              flex: "1",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "end",
                            }}
                          >
                            <DestinationFolderBadge
                              destinationFolder={item.destinationFolder}
                              storageProviderId={item.storageProvider}
                            />
                          </Box>
                        </CardActionArea>
                      </Card>
                    </Grid>
                  );
                }
              )}
          </Grid>
        </Box>
      </Box>
      <div className={classes.buttonContainer}>
        <Button
          sx={{
            marginRight: theme.spacing(2),
          }}
          onClick={handleModalClosed}
        >
          {t("cancel_action")}
        </Button>
        <LoadingButton
          className={classes.applyButton}
          color="primary"
          variant="contained"
          loading={createUploadLinkResult.loading}
          disabled={!selectedStorageId}
          onClick={() => {
            onStorageSelected(selectedStorageId);
          }}
          sx={{
            textTransform: "uppercase",
          }}
        >
          {t("choose_action")}
        </LoadingButton>
      </div>
    </Box>
  );
};

interface SelectFilesToSaveToCloudContainerProps {
  shareLinkToken: string;
  selectedStorageId: string;
  handleModalClose: () => void;
}

const SelectFilesToSaveToCloudContainer = ({
  shareLinkToken,
  selectedStorageId,
  handleModalClose,
}: SelectFilesToSaveToCloudContainerProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const classes = useStyles();

  const [retrieveShareLinkFiles, retrieveShareLinkFilesResult] = useLazyQuery(
    retrieveFilesForShareLinkGql,
    {
      variables: {
        token: shareLinkToken,
      },
      context: {
        clientName: "public",
      },
    }
  );

  const [saveSharedLinkFiles, saveSharedLinkFilesResult] = useMutation(
    saveSharedLinkFilesToCloudGql
  );

  const sharedLinkFiles: ShareLinkFile[] | null =
    retrieveShareLinkFilesResult.data?.shareLinkFiles;

  const [unselectedFiles, setUnselectedFiles] = useState<string[]>([]); //we store the ids of the files that are unselected by the user

  const handleFileSelectionChange = useCallback(
    (fileId: string, selected: boolean) => {
      if (selected) {
        setUnselectedFiles(unselectedFiles.filter((item) => item !== fileId));
      } else {
        setUnselectedFiles([...unselectedFiles, fileId]);
      }
    },
    [unselectedFiles]
  );
  /* 
  React.useEffect(() => {
    console.log(
      "handleFileSelectionChange: list of unselected files is %o ",
      unselectedFiles
    );
  }, [unselectedFiles]); */

  React.useEffect(() => {
    const token = shareLinkToken;
    if (token) {
      retrieveShareLinkFiles();
    }
  }, []);

  const handleSaveToMyCloud = useCallback(() => {
    saveSharedLinkFiles({
      variables: {
        sharedLinkToken: shareLinkToken,
        cloudStorageId: selectedStorageId,
        skipItemsIds: unselectedFiles,
      },
    }).then((result) => {
      console.log("Got result %o", result);
    });
  }, [shareLinkToken, unselectedFiles]);

  if (saveSharedLinkFilesResult.loading) {
    return <FilesLoadingView />;
  }
  if (
    saveSharedLinkFilesResult.data &&
    saveSharedLinkFilesResult.data.saveShareLinkFile
  ) {
    if (saveSharedLinkFilesResult.data.saveShareLinkFile.success) {
      return (
        <Box
          sx={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Lottie
            style={{ height: "auto", width: "50%" }}
            animationData={uploadCompleteAnimationData}
            loop={true}
            autoplay
          />

          <Typography
            variant="h4"
            sx={{
              position: "absolute",
              bottom: theme.spacing(4),
            }}
          >
            {t("upload_complete_message")}
          </Typography>

          <Button
            sx={{
              position: "absolute",
              bottom: 0,
              marginTop: theme.spacing(2),
            }}
            onClick={handleModalClose}
          >
            {t("ok")}
          </Button>
        </Box>
      );
    } else if (
      saveSharedLinkFilesResult.data.saveShareLinkFile.failedUploadFiles
        .length > 0
    ) {
      return (
        <SomeFilesUploadFailedView
          handleModalClose={handleModalClose}
          sharedFiles={
            saveSharedLinkFilesResult.data.saveShareLinkFile.failedUploadFiles
          }
        />
      );
    }
  }

  /*  return (
    <Box
      sx={{
        padding: theme.spacing(4),
      }}
    >
      <FilesLoadingView />
    </Box>
  ); */

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        px: theme.spacing(2),
        justifyContent: "space-between",
        overflowY: "hidden",
        transition: theme.transitions.create("all", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          flexDirection: "column",
          px: theme.spacing(2),
          overflowY: "hidden",
          marginTop: theme.spacing(2),
          flexGrow: 1,
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{
            marginTop: theme.spacing(3),
            overflowY: "hidden",
            color: theme.palette.primary.main,
            width: "100%",
          }}
        >
          {t("select_files_to_save_title")}
        </Typography>

        <Typography
          variant="caption"
          sx={{
            marginBottom: theme.spacing(1),
            overflowY: "hidden",
            width: "100%",
          }}
        >
          {t("select_files_to_save_description")}
        </Typography>
        {sharedLinkFiles && (
          <Typography
            variant="subtitle2"
            sx={{ fontWeight: "bold", color: "gray" }}
          >
            {sharedLinkFiles.length -
              unselectedFiles.length +
              "/" +
              sharedLinkFiles.length +
              " " +
              t("selected_title")}
          </Typography>
        )}
        {/*  {retrieveShareLinkFilesResult.data?.shareLinkFiles && (
          <SomeFilesUploadFailedView
            handleModalClose={handleModalClose}
            sharedFiles={retrieveShareLinkFilesResult.data.shareLinkFiles}
          />
        )} */}
        <Grid
          container
          spacing={1}
          sx={{
            overflowY: "auto",
            display: "flex",
            justifyContent: "start",
            // justifyContent:"center"
            [theme.breakpoints.down("md")]: {
              px: theme.spacing(4),
              height: "100%",
            },
          }}
        >
          {retrieveShareLinkFilesResult.data &&
            retrieveShareLinkFilesResult.data.shareLinkFiles &&
            retrieveShareLinkFilesResult.data.shareLinkFiles.map(
              (shareLinkFile: ShareLinkFile) => {
                return (
                  <>
                    <Grid
                      key={shareLinkFile.id}
                      sx={{
                        width: "100%",
                        alignItems: "center",
                        display: "flex",
                        py: theme.spacing(0.4),
                        [theme.breakpoints.down("sm")]: {
                          justifyContent: "center",
                          alignItems: "center",
                        },
                      }}
                      item
                      xs={6}
                      sm={3}
                      md={3}
                    >
                      <Card
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          height: "100%",
                          boxShadow:
                            "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px",
                          borderRadius: "8px",
                          my: theme.spacing(1),
                          width: "100%",
                          overflow: "hidden",
                        }}
                      >
                        {shareLinkFile.hasPreview ? (
                          <img
                            style={{
                              objectFit: "cover",
                            }}
                            height="100px"
                            width="100%"
                            src={getSharedFilePreview(shareLinkFile.id)}
                          />
                        ) : (
                          <Box
                            sx={{
                              height: "100px",
                              display: "flex",
                              flexDirection: "center",
                              justifyContent: "center",
                              alignItems: "center",
                              background: theme.palette.primary.main,
                            }}
                          >
                            {getIconForFileType(shareLinkFile.fileType)}
                          </Box>
                        )}
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            // marginLeft: theme.spacing(1),
                            width: "100%",
                            px: theme.spacing(0.5),
                            py: theme.spacing(1),
                            overflow: "hidden",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                              overflow: "hidden",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: ".9rem",
                                fontWeight: "bold",
                                display: "-webkit-box",
                                wordWrap: "break-word",
                                textOverflow: "ellipsis",
                                WebkitBoxOrient: "vertical",
                                WebkitLineClamp: 2,
                                overflow: "hidden",
                                maxLines: 2,
                              }}
                            >
                              {shareLinkFile.fileName}
                            </Typography>
                            <Typography variant="caption">
                              {getSizeInHumeanReadableFormat(
                                shareLinkFile.fileSize
                              )}
                              {" • " + shareLinkFile.fileType}
                            </Typography>
                          </Box>
                          <Box>
                            <Checkbox
                              defaultChecked
                              onChange={(
                                event: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                handleFileSelectionChange(
                                  shareLinkFile.id,
                                  event.target.checked
                                );
                              }}
                              checkedIcon={
                                <CheckCircleIcon
                                  sx={{
                                    heihgt: "20px",
                                    width: "20px",
                                  }}
                                />
                              }
                              icon={
                                <RadioButtonUncheckedIcon
                                  sx={{
                                    heihgt: "20px",
                                    width: "20px",
                                    color: theme.palette.primary.main,
                                  }}
                                />
                              }
                            />
                          </Box>
                        </Box>
                      </Card>
                    </Grid>
                  </>
                );
              }
            )}
        </Grid>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          paddingBottom: "24px",
        }}
      >
        <Button
          sx={{
            marginRight: theme.spacing(2),
          }}
          onClick={handleModalClose}
        >
          {t("cancel_action")}
        </Button>
        <LoadingButton
          className={classes.applyButton}
          color="primary"
          variant="contained"
          // loading={createUploadLinkResult.loading}
          // disabled={!selectedStorageId}
          onClick={handleSaveToMyCloud}
          sx={{
            textTransform: "uppercase",
          }}
        >
          {t("save_to_my_cloud_action")}
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default React.memo(SaveSharedFileToCloud);

import {
  Box,
  Button,
  CircularProgress,
  Container,
  Drawer,
  IconButton,
  LinearProgress,
  Link,
  SwipeableDrawer,
  TextField,
  Tooltip,
  Typography,
  keyframes,
  useTheme,
} from "@mui/material";
import { useResponsive } from "hooks/use-responsive";
import React, { useCallback, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useTranslation } from "react-i18next";
import { LOGIN, PRICING } from "navigation/Constants";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import { DEBUG, getPublicShareLinkFromToken } from "configuration";
import {
  getRemainingTime,
  getSizeInHumeanReadableFormat,
} from "shared/dataUtils";
import Lottie from "lottie-react";
import animationData from "res/animations/complete_animation.json";
import { startUploadSessionGql, uploadPublicFileGql } from "graphql/mutations";
import { useMutation } from "@apollo/client";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { ShareLink } from "models/shareLink";
import ClearIcon from "@mui/icons-material/Clear";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import {
  SubscriptionPlanIds,
  UserObj,
  getUserMaxShareLinkFilesSize,
} from "models/user";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import TextPicker from "../TextPicker";
import { showUpgradePlanDialog } from "store/view";

interface CreateNewShareLinkDrawerProps {
  open: boolean;
  handleClose: () => void;
}

interface UploadableFile {
  fileObj: File;
  progress: number;
  isUploaded: boolean;
}

const rippleEffect = keyframes`
    0% {
      box-shadow: 0 0 0 0 #1877f2;
    }
    70% {
      box-shadow: 0 0 0 10px rgba(0, 0, 255, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(0, 0, 255, 0);
    }
  `;

const CreateNewShareLinkDrawer = ({
  open,
  handleClose,
}: CreateNewShareLinkDrawerProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const hiddenFileInput = React.useRef<HTMLInputElement>(null);
  const [selectedFiles, setSelectedFiles] = useState<any>([]);
  const [
    fileBeingUploaded,
    setFileBeingUploaded,
  ] = React.useState<UploadableFile | null>(null);
  const [progress, setProgress] = useState<number>(0);
  const abort = React.useRef<any>();
  const [uploadOngoing, setUploadOngoing] = useState(false);
  const [uploadCompleted, setUploadCompleted] = useState(false);
  const userObj: UserObj | null = useSelector(
    (state: RootState) => state.auth.user
  );
  const titleRef = useRef<HTMLInputElement>(null);
  const messageRef = useRef<HTMLInputElement>(null);
  const downMd = useResponsive("down", "md");

  const supportedExpiryDates = ["3days", "7days", "14days", "1month"];

  const expiryDateOptions: any = {};

  expiryDateOptions["3days"] = { label: t("3_days"), value: 3 };
  expiryDateOptions["7days"] = { label: t("7_days"), value: 7 };
  expiryDateOptions["14days"] = { label: t("14_days"), value: 14 };
  expiryDateOptions["1month"] = { label: t("30_days"), value: 30 };

  const getExpiryForTextPicker = React.useCallback(
    (expiryDaysCount: number) => {
      switch (expiryDaysCount) {
        case 3:
          return expiryDateOptions["3days"];
        case 7:
          return expiryDateOptions["7days"];
        case 14:
          return expiryDateOptions["14days"];
        case 30:
          return expiryDateOptions["1month"];
        default:
          return expiryDateOptions["7days"];
      }
    },
    [expiryDateOptions]
  );
  const [selectedExpiryDate, setSelectedExpiryDate] = React.useState(
    getExpiryForTextPicker(7)
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [uploadFile, uploadFileResult] = useMutation(uploadPublicFileGql, {
    context: {
     // clientName: "public",
      fetchOptions: {
        useUpload: true,
        onProgress: (ev: ProgressEvent) => {
          console.log("Got new progress event");
          const progress = ev.loaded / ev.total;
          if (fileBeingUploaded) {
            fileBeingUploaded.progress = progress;
            //setFileBeingUploaded(fileBeingUploaded)
          }
          setProgress(ev.loaded / ev.total);
        },
        onAbortPossible: (abortHandler: any) => {
          abort.current = abortHandler;
        },
      },
    },
  });

  const [startUploadSession, startUploadSessionResult] = useMutation(
    startUploadSessionGql,
   /*  {
      context: {
        clientName: "public",
      },
    } */
  );

  function onFileInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    const filesList = e.target.files;
    if (!filesList) {
      return;
    }
    updateSelectedFiles(filesList);
  }

  function updateSelectedFiles(filesList: FileList) {
    var totalfilesSize = 0;
    var totalfilesCount = 0;
    var uploadableFilesList: UploadableFile[] = Array.from(filesList).map(
      (file: File) => {
        totalfilesSize += file.size;
        totalfilesCount++;
        const uploadableFileObject = {
          fileObj: file,
          progress: 0,
          isUploaded: false,
        };
        return uploadableFileObject;
      }
    );

    setSelectedFiles([...uploadableFilesList, ...selectedFiles]);
  }

  const handleRemoveFile = React.useCallback(
    (fileToRemove: UploadableFile) => {
      const newArray = selectedFiles.filter(
        (file: UploadableFile) => file !== fileToRemove
      );
      setSelectedFiles(newArray);
    },
    [selectedFiles]
  );

  const doUploadFiles = useCallback(
    async (sessionId: string) => {
      if (!selectedFiles) {
        return;
      }
      var i = 0;
      for (const uploadableFile of selectedFiles) {
        i++;
        if (uploadableFile.isUploaded) {
          if (DEBUG) {
            console.log("Skipping file %o", uploadableFile.fileObj);
          }
          continue;
        }

        const fileObj = uploadableFile.fileObj;
        if (DEBUG) {
          console.log(
            "Adding   new file " +
              JSON.stringify(fileObj.type) +
              " size " +
              JSON.stringify(fileObj.size)
          );
        }
        setFileBeingUploaded(uploadableFile);
        var uploadInput = {
          sessionId: sessionId,
          file: fileObj,
        };

        await uploadFile({
          variables: {
            ...uploadInput,
          },
        }).then((result) => {
          if (result.data.uploadPublicFile.result) {
            uploadableFile.isUploaded = true;
            // setUploadedFilesList([fileObj, ...uploadedFilesList]);
          }
        });
      }
      setUploadOngoing(false);
      setUploadCompleted(true);
      //const filesCount = files.length;
    },
    [selectedFiles, setUploadOngoing, setUploadCompleted, setFileBeingUploaded]
  );

  const startUpload = useCallback(() => {
    setUploadOngoing(true);

    const title = titleRef.current?.value;
    const message = messageRef.current?.value;
    startUploadSession({
      variables: {
        title: title?.trim(),
        message: message?.trim(),
        expiry: selectedExpiryDate.value,
      },
    }).then((result) => {
      if (
        result.data &&
        result.data.startPublicUploadSession &&
        result.data.startPublicUploadSession.shareLink
      ) {
        const sessionId = result.data.startPublicUploadSession.shareLink.id;
        doUploadFiles(sessionId);
      }
    });
  }, [
    startUploadSession,
    titleRef.current,
    messageRef.current,
    doUploadFiles,
    selectedExpiryDate,
  ]);

  const [dragActive, setDragActive] = useState(false);

  // Handle when files are dropped
  const handleDrop = (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    setDragActive(false);
    // const droppedFiles = Array.from(event.dataTransfer.files);
    //setFiles((prevFiles) => [...prevFiles, ...droppedFiles]);
    updateSelectedFiles(event.dataTransfer.files);
  };

  // Handle drag over
  const handleDragOver = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setDragActive(true);
  };

  // Handle drag leave
  const handleDragLeave = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setDragActive(false);
  };

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <Drawer
        sx={{
          "& .MuiDrawer-paper": {
            height: "100%",
            width: "80%",
            display: "flex",
            borderRadius: "16px",
          },
        }}
        anchor="right"
        open={open}
        onClose={handleClose}
      >
        <IconButton
          sx={{
            width: "40px",
            height: "40px",
            posiiton: "absolute",
            left: theme.spacing(2),
            top: theme.spacing(2),
          }}
          onClick={() => {
            handleClose();
          }}
        >
          <ClearIcon />
        </IconButton>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            maxWidth: "100%",
            height: "100%",
            position: "relative",
            overflow: "hidden",
            px: theme.spacing(4),
            py: theme.spacing(8),
            [theme.breakpoints.down("sm")]: {
              flexDirection: "column",
            },
          }}
        >
          {!uploadCompleted && (
            <>
              <Box
                sx={{
                  width: !downMd || selectedFiles.length > 0 ? "50%" : "100%",
                  display: "flex",
                  height: "100%",
                  flexDirection: "column",
                  px: theme.spacing(4),
                  [theme.breakpoints.down("sm")]: {
                    display: selectedFiles.length > 0 ? "none" : "flex",
                  },
                }}
              >
                <Box
                  sx={{
                    marginTop: theme.spacing(4),
                    px: theme.spacing(4),
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <input
                    ref={hiddenFileInput}
                    accept={"*/*"}
                    onChange={onFileInputChange}
                    type="file"
                    multiple
                    style={{ display: "none" }}
                  />
                  {!uploadOngoing && (
                    <>
                      <IconButton
                        sx={{
                          position: "relative",
                          borderRadius: "50%",
                          padding: 0,
                          "&::before": {
                            content: '""',
                            position: "absolute",
                            width: "56px",
                            height: "56px",
                            alignItems: "center",
                            borderRadius: "50%",
                            animation: `${rippleEffect} 2s infinite`,
                          },
                        }}
                        onClick={() => {
                          hiddenFileInput?.current?.click();
                        }}
                      >
                        <AddCircleIcon
                          sx={{
                            width: "48px",
                            height: "48px",
                            color: theme.palette.primary.main,
                          }}
                        />
                      </IconButton>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          mx: theme.spacing(2),
                        }}
                      >
                        <Typography variant="h5" sx={{}}>
                          {t("upload_files_title")}
                        </Typography>
                        <Typography variant="caption" sx={{}}>
                          {t("upload_files_connected_user_subtitle", {
                            max_size: getUserMaxShareLinkFilesSize(userObj),
                          })}
                          . &nbsp;
                          {userObj?.subscriptionPlan.id !=
                            SubscriptionPlanIds.BUSINESS &&
                            t("need_more_question")}
                          <Link
                            sx={{
                              marginLeft: theme.spacing(1),
                              marginRight: theme.spacing(1),
                              cursor: "pointer",
                            }}
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                              navigate(PRICING);
                            }}
                          >
                            {t("upgrade_plan_button_title")}
                          </Link>
                        </Typography>
                        <Typography
                          variant="caption"
                          sx={{
                            marginTop: theme.spacing(1),
                          }}
                        ></Typography>
                      </Box>
                    </>
                  )}
                  {uploadOngoing && (
                    <>
                      <CircularProgress></CircularProgress>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          mx: theme.spacing(2),
                        }}
                      >
                        <Typography variant="body1" sx={{}}>
                          {t("file_upload_ongoing_message")}
                        </Typography>
                        {/*  <Typography variant="caption" sx={{}}>
                          {t("upload_files_subtitle")}
                        </Typography> */}
                      </Box>
                    </>
                  )}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: theme.spacing(6),
                  }}
                >
                  <TextField
                    inputRef={titleRef}
                    name="title"
                    disabled={uploadOngoing}
                    placeholder={t("title")}
                    sx={{
                      marginTop: theme.spacing(2),
                    }}
                  />
                  <TextField
                    inputRef={messageRef}
                    name="message"
                    disabled={uploadOngoing}
                    placeholder={t("message_title")}
                    multiline
                    rows={4} // Number of initial rows
                    maxRows={20} // Maximum number of rows before scrolling
                    sx={{
                      marginTop: theme.spacing(2),
                      "& .MuiInputBase-input::placeholder": {},
                      padding: 0,
                      "& .MuiInputBase-root": {
                        padding: "8px",
                      },
                      "& .MuiInputBase-input": {
                        padding: 0,
                        alignContent: "top",
                        textAlign: "top",
                      },
                    }}
                  />

                  <Box
                    sx={{
                      marginTop: theme.spacing(4),
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="subtitle2">
                      {t("expires_in_title")}
                    </Typography>
                    <TextPicker
                      sx={{
                        width: "60%",
                      }}
                      defaultValue={selectedExpiryDate}
                      value={selectedExpiryDate}
                      options={supportedExpiryDates.map(
                        (element) => expiryDateOptions[element]
                      )}
                      onChange={(expiryDateDays: number) => {
                        if (
                          userObj?.subscriptionPlan.id ==
                          SubscriptionPlanIds.FREE
                        ) {
                          dispatch(
                            showUpgradePlanDialog({
                              show: true,
                              message: null,
                            })
                          );
                          return;
                        }
                        setSelectedExpiryDate(
                          getExpiryForTextPicker(expiryDateDays)
                        );
                      }}
                    />
                    {/*  <Button>Expiry</Button> */}
                    {/*   <Menu
                    id="lock-menu"
                    anchorEl={expiryMenuAnchorElement}
                    open={Boolean(expiryMenuAnchorElement)}
                    onClose={() => {
                      setAnchorElement(null);
                      setOpenEditConnectedStorageMenu(false);
                    }}
                    MenuListProps={{
                      "aria-labelledby": "lock-button",
                      role: "listbox",
                    }}
                  >
                    <MenuItem>BLABLA</MenuItem>
                  </Menu> */}
                  </Box>
                </Box>
              </Box>
              {(!selectedFiles || selectedFiles.length == 0) && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    background: dragActive ? "#D0D0D0" : "#f9f9f9",
                    height: "100%",
                    width: "100%",
                    maxWidth: "50%",
                    borderColor: dragActive
                      ? theme.palette.primary.main
                      : "gray",
                    borderStyle: "dashed",
                    borderWidth: "4px",
                    strokeWidth: "40px",
                    borderRadius: theme.spacing(1),
                  }}
                  onDragOver={handleDragOver}
                  onDragLeave={handleDragLeave}
                  onDrop={handleDrop}
                >
                  {" "}
                  <UploadFileIcon />
                  <Typography
                    sx={{
                      marginTop: theme.spacing(2),
                    }}
                  >
                    {t("drag_and_drop_message")}
                  </Typography>
                </Box>
              )}
            </>
          )}
          {uploadCompleted &&
            startUploadSessionResult.data?.startPublicUploadSession && (
              <ShareLinkView
                shareLink={
                  startUploadSessionResult.data.startPublicUploadSession
                    .shareLink
                }
              />
            )}

          <Box
            sx={{
              width: selectedFiles.length > 0 ? "100%" : "0%",
              maxWidth: "50%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              marginTop: theme.spacing(2),
              transition: theme.transitions.create("all", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
              }),
              "&::-webkit-scrollbar": {
                width: 3,
              },
              "&::-webkit-scrollbar-track": {
                boxShadow: `inset 0 0 6px #faf6e9`,
                borderRadius: "4px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: theme.palette.primary.main,
                borderRadius: "4px",
              },
              overflowY: "auto",
              [theme.breakpoints.down("sm")]: {
                width: selectedFiles.length > 0 ? "100%" : "0%",
                display: uploadCompleted ? "none" : "block",
                maxWidth: "100%",
              },
            }}
          >
            <Box
              sx={{
                height: "100%",
                width: "100%",
                overflowY: "auto",
              }}
            >
              {selectedFiles.map((uploadableFile: UploadableFile) => {
                return (
                  <UploadFilePreviewItem
                    fileBeingUploaded={fileBeingUploaded}
                    uploadableFile={uploadableFile}
                    isUploaded={uploadableFile.isUploaded}
                    onDelete={handleRemoveFile}
                    progress={progress}
                  />
                );
              })}
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "end",
                px: theme.spacing(2),
                marginTop: theme.spacing(2),
              }}
            >
              <Button
                onClick={() => {
                  startUpload();
                }}
                variant="contained"
              >
                {t("upload_action")}
              </Button>
            </Box>
          </Box>
        </Box>
      </Drawer>
    </div>
  );
};

interface ShareLinkViewProps {
  shareLink: ShareLink;
}

const ShareLinkView = ({ shareLink }: ShareLinkViewProps) => {
  const [displayUrlCopiedTooltip, setDisplayUrlCopiedTooltip] = React.useState(
    false
  );
  const copyLinkToClipboard = React.useCallback(() => {
    navigator.clipboard.writeText(getPublicShareLinkFromToken(shareLink.token));
    setDisplayUrlCopiedTooltip(true);
    setTimeout(() => {
      setDisplayUrlCopiedTooltip(false);
    }, 2000);
  }, [shareLink]);
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "50%",
        height: "20%",
        justifyContent: "top",
        alignItems: "center",
        // paddingTop: "10%",
        px: theme.spacing(1),
        [theme.breakpoints.down("sm")]: {
          width: "100%",
        },
      }}
    >
      <Lottie
        style={{ height: "200px", width: "200px" }}
        animationData={animationData}
        loop={true}
        autoplay
      />
      <Typography>{t("share_link_ready_message")} 👇</Typography>

      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          px: theme.spacing(1),
          marginTop: "16px",
          marginBottom: "16px",
          alignItems: "center",
          border: "dashed",
          borderRadius: "8px",
          borderColor: theme.palette.primary.main,
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            overflow: "auto",
            scrollbarWidth: "1px",
            "&::-webkit-scrollbar": {
              height: 0,
            },
          }}
        >
          <InsertLinkIcon></InsertLinkIcon>
          <Typography
            sx={{
              marginLeft: "16px",
              wordWrap: "break-word",
            }}
          >
            {getPublicShareLinkFromToken(shareLink.token)}
          </Typography>
        </Box>
        <Tooltip open={displayUrlCopiedTooltip} title={t("link_copied_msg")}>
          <Button
            sx={{
              color: "gray",
              fontSize: ".7rem",
            }}
            onClick={copyLinkToClipboard}
          >
            {t("copy_link_action")}
          </Button>
        </Tooltip>
      </Box>

      <Typography
        variant="caption"
        sx={{
          width: "100%",
          px: theme.spacing(2),
        }}
      >
        {t("expires_in", {
          expiry: getRemainingTime(shareLink.expiryDate, t),
        })}
      </Typography>
    </Box>
  );
};

interface UploadFilePreviewItemProps {
  uploadableFile: UploadableFile;
  fileBeingUploaded: UploadableFile | null;
  onDelete: (file: UploadableFile) => void;
  progress?: number;
  isUploaded: boolean;
}

const shouldDisplayItemPreview = (fileObj: File): boolean => {
  return fileObj.type.startsWith("image/");
};

const UploadFilePreviewItem = ({
  uploadableFile,
  fileBeingUploaded,
  onDelete,
  progress = 0,
  isUploaded,
}: UploadFilePreviewItemProps) => {
  const theme = useTheme();
  const [imageURL, setImageURL] = useState<string | null>(null);
  const [localProgress, setLocalProgress] = useState<number>(0);
  const [displayPreview, setDisplayPreview] = useState(
    shouldDisplayItemPreview(uploadableFile.fileObj)
  );

  const { t } = useTranslation();
  React.useEffect(() => {
    setLocalProgress(progress);
  }, [progress]);

  React.useEffect(() => {
    const reader = new FileReader();
    reader.onloadend = () => {
      setImageURL(reader.result as string);
    };
    reader.readAsDataURL(uploadableFile.fileObj);
  }, []);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        px: theme.spacing(2),
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
          overflow: "hidden",
          marginTop: theme.spacing(2),
          alignItems: "center",
        }}
      >
        {/*      {imageURL && displayPreview && (
              <img
                  style={{
                  objectFit: "cover",
                  height: "40px",
                  width: "40px",
                  borderRadius: "4px"
                  }}
                  height="40px"
                  width="40px"
                  src={imageURL}
              />
              )}
              {!imageURL && displayPreview && (
              <Skeleton
                  animation="wave"
                  variant="rectangular"
                  width="100%"
                  height="100px"
              />
              )} */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            // marginLeft: theme.spacing(1),
            width: "90%",
          }}
        >
          <Typography
            sx={{
              fontSize: ".9rem",
              fontWeight: "bold",
            }}
            noWrap
          >
            {uploadableFile.fileObj.name}
          </Typography>
          <Typography variant="caption">
            {getSizeInHumeanReadableFormat(uploadableFile.fileObj.size)}
            {" • " + uploadableFile.fileObj.type}
          </Typography>
        </Box>

        {isUploaded ? (
          <CheckCircleIcon
            sx={{
              color: "green",
              marginLeft: theme.spacing(1),
            }}
          />
        ) : (
          <>
            {uploadableFile == fileBeingUploaded ? (
              <>
                {localProgress == 1 ? (
                  <CircularProgress size={"24px"} variant="indeterminate" />
                ) : (
                  <Typography
                    sx={{
                      color: "white",
                      fontSize: ".8rem",
                    }}
                  >
                    {`${Math.round(localProgress * 100)}%`}
                  </Typography>
                )}
              </>
            ) : (
              <Tooltip title={t("delete_action")}>
                <IconButton
                  sx={{
                    flex: "1",
                  }}
                  onClick={() => {
                    onDelete(uploadableFile);
                  }}
                >
                  <DeleteOutlineIcon />
                </IconButton>
              </Tooltip>
            )}
          </>
        )}
      </Box>
      {uploadableFile == fileBeingUploaded && !uploadableFile.isUploaded && (
        <LinearProgress
          sx={{
            width: "100%",
          }}
          defaultValue={0}
          value={localProgress * 100}
          variant="determinate"
        />
      )}
    </Box>
  );
};

export default React.memo(CreateNewShareLinkDrawer);

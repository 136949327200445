import { Box, IconButton, useTheme } from "@mui/material";
import { HEADER, SPACING } from "config/config-layout";
import React, { useEffect, useState } from "react";
import CurrentUserAvatarMenu from "components/ui/menu/CurrentUserAvatar";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import UploadlyFullIcon from "res/icons/uploadly-logo.svg";
import { useNavigate } from "react-router-dom";
import { ROOT } from "navigation/Constants";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { fetchUserData } from "api/authApi";
import { UserObj } from "models/user";
import { loginUser, logoutUser } from "store/auth";

const ShareLinkPageHeader = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.auth.user);
  const isUserLoggedIn = useSelector((state: RootState) => state.auth.isUserLoggedIn);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!user && isUserLoggedIn) {
      fetchUserData()
        .then((user: UserObj) => {
          console.log("Dispatch user " + user);
          dispatch(loginUser(user));
        })
        .catch((err) => {
          console.log("Caught an error while fetch user data" + err);
          if (err?.message?.includes("403")) {
            dispatch(logoutUser());
          }
        });
    }
  }, [user]);

  return (
    <Box
      sx={{
        height: `${HEADER.H_MOBILE + SPACING}px`,
        width: "100%",
        alignItems: "center",
        display: "flex",
        justifyContent: "end",
        px: theme.spacing(8),
        //  height: "100vw",
        [theme.breakpoints.down("lg")]: {
          // justifyContent: "space-between" : "end",
          px: theme.spacing(2),
        },
      }}
    >
      {user && <CurrentUserAvatarMenu />}
      {!user && (
        <img
          src={UploadlyFullIcon}
          width={"36px"}
          onClick={() => {
            navigate(ROOT);
          }}
        />
      )}
    </Box>
  );
};

export default React.memo(ShareLinkPageHeader);

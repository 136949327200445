export interface ShareLink {
  id?: string;
  title: string;
  message: string;
  filesCount: number;
  filesTotalSize: number;
  createdAt: string;
  token: string;
  expiryDate: string;
  status: ShareLinkStatus;
}

export interface ShareLinkFile {
  id: string;
  fileName: string;
  fileSize: number;
  fileType: string;
  hasPreview: boolean;
  createdAt: string;
}


export enum ShareLinkStatus { //should be in sync with values from the backend
  ACTIVE = 0,
  DISABLED = 1,
  EXPIRED = 2,
}

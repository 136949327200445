import {
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  IconButton,
  LinearProgress,
  Skeleton,
  Stack,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
  alpha,
  keyframes,
  useTheme,
} from "@mui/material";
import React, { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  getRemainingTime,
  getSizeInHumeanReadableFormat,
} from "shared/dataUtils";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useMutation } from "@apollo/client";
import { startUploadSessionGql, uploadPublicFileGql } from "graphql/mutations";
import { DEBUG, getPublicShareLinkFromToken } from "configuration";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import InsertLinkIcon from "@mui/icons-material/InsertLink";

import animationData from "res/animations/complete_animation.json";
import Lottie from "lottie-react";
import { ShareLink } from "models/shareLink";
import UploadFilesContainer from "components/ui/UploadFilesContainer";
import LandingUploadlyPreviewMP4 from "res/gifs/collect_files_landing_preview.mp4";
import SaveEmailAttachementsPreviewMp4 from "res/gifs/save_email_attachments_landing_preview.mp4";
import GoogleIcon from "res/icons/google_icon.svg";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LOGIN } from "navigation/Constants";
import { useResponsive } from "hooks/use-responsive";
import { startGooglelogin } from "store/view";

const TAB_SHARE_FILE = "share_file";
const TAB_COLLECT_FILE = "collect_file";
const TAB_SAVE_EMAIL_ATTACHMENTS_FILE = "save_email_attachements";

const rippleEffect = keyframes`
  0% {
    box-shadow: 0 0 0 0 #1877f2;
  }
  70% {
    box-shadow: 0 0 0 10px rgba(0, 0, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 0, 255, 0);
  }
`;
interface UploadableFile {
  fileObj: File;
  progress: number;
  isUploaded: boolean;
}
const DownloadUploadContainer = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [tabValue, setTabValue] = React.useState(TAB_SHARE_FILE);

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "left",
        width: "100%",
        pt: { xs: 7, sm: 10 },
        pb: { xs: 4, sm: 12 },
      }}
    >
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "85vh",

          outline: "1px solid",
          outlineColor:
            theme.palette.mode === "light"
              ? alpha("#BFCCD9", 0.5)
              : alpha("#9CCCFC", 0.1),
          boxShadow:
            theme.palette.mode === "light"
              ? `0 0 12px 8px ${alpha(theme.palette.primary.main, 0.1)}`
              : `0 0 24px 12px ${alpha("#033363", 0.2)}`,
          transition: theme.transitions.create("all", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
        }}
      >
        <Tabs
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
          value={tabValue}
          onChange={(event: React.SyntheticEvent, newValue: any) => {
            console.log("Got a change here " + JSON.stringify(newValue));
            setTabValue(newValue);
          }}
          variant="scrollable"
          scrollButtons={false}
          centered
        >
          <Tab value={TAB_SHARE_FILE} label={t("landing_tab_share_file")} />
          <Tab value={TAB_COLLECT_FILE} label={t("landing_tab_collect_file")} />
          <Tab
            value={TAB_SAVE_EMAIL_ATTACHMENTS_FILE}
            label={t("landing_tab_save_email_attachments")}
          />
        </Tabs>
        {tabValue == TAB_SHARE_FILE && <UploadFilesContainer />}
        {tabValue == TAB_COLLECT_FILE && <CollectFilesView />}
        {tabValue == TAB_SAVE_EMAIL_ATTACHMENTS_FILE && (
          <SaveEmailAttachmentsView />
        )}
      </Card>
    </Container>
  );
};

const SaveEmailAttachmentsView = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const downMd = useResponsive("down", "md");

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
        width: "100%",
        px: theme.spacing(4),
        overflow: "hidden",
        [theme.breakpoints.down("md")]: {
          px: theme.spacing(1),
        },
      }}
    >
      <Stack
        spacing={1}
        useFlexGap
        justifyContent={"start"}
        sx={{ width: "100%", py: theme.spacing(3) }}
      >
        <Typography
          variant="h5"
          sx={{
            position: "relative",
            flexDirection: { xs: "column", md: "row" },
            alignSelf: "center",
            textAlign: "start",
            fontSize: "clamp(3.5rem, 10vw, 4rem)",
            color: "#0b3558",
            [theme.breakpoints.down("md")]: {
              fontSize: "clamp(1rem, 10vw, 1rem)",
              
            },
          }}
        >
         {t('landing_tab_save_email_attachments_content_title')}
        </Typography>
      </Stack>

      <video
        autoPlay
        loop
        muted
        style={{
          width: "100%",
          height: "80%",
          borderRadius: "10px",
          outline: "1px solid",
          objectFit: !downMd ? "cover" : "contain",
          outlineColor:
            theme.palette.mode === "light"
              ? alpha("#BFCCD9", 0.5)
              : alpha("#9CCCFC", 0.1),
        }}
        src={SaveEmailAttachementsPreviewMp4}
      >
        <source
          src={SaveEmailAttachementsPreviewMp4}
          type="video/mp4"
          height={"100%"}
        />
        Your browser does not support the video tag.
      </video>
    </Box>
  );
};

const CollectFilesView = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const downMd = useResponsive("down", "md");

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        height: "100%",
        width: "100%",
        px: theme.spacing(4),
        overflow: "hidden",
      }}
    >
      <Stack
        useFlexGap
        sx={{
          width: "100%",
          height: "100%",
          alignItems: "center",
          display: "flex",
          flexDirection: "row",
          px: theme.spacing(2),
          [theme.breakpoints.down("md")]: {
          },
        }}
      >
        <Stack
          spacing={2}
          useFlexGap
          justifyContent={"start"}
          sx={{ width: "100%" }}
        >
          <Typography
            variant="h5"
            sx={{
              position: "relative",
              flexDirection: { xs: "column", md: "row" },
              alignSelf: "center",
              textAlign: "start",
              fontSize: "clamp(3.5rem, 10vw, 4rem)",
              color: "#0b3558",
              [theme.breakpoints.down("md")]: {
                fontSize: "clamp(1.5rem, 10vw, 2rem)",
              },
            }}
          >
            {t("create_share_links_landing_title")}
          </Typography>
          <Typography
            textAlign="start"
            color="text.secondary"
            sx={{ alignSelf: "start", width: { sm: "100%", md: "80%" } }}
          >
            {t("create_share_links_landing_step1")}
          </Typography>
          <Typography
            textAlign="start"
            color="text.secondary"
            sx={{ alignSelf: "start", width: { sm: "100%", md: "80%" } }}
          >
            {t("create_share_links_landing_step2")}
          </Typography>
          <Typography
            textAlign="start"
            color="text.secondary"
            sx={{ alignSelf: "start", width: { sm: "100%", md: "80%" } }}
          >
            {t("create_share_links_landing_step3")}
          </Typography>
          <Typography
            textAlign="start"
            color="text.secondary"
            sx={{ alignSelf: "start", width: { sm: "100%", md: "80%" } }}
          >
            {t("create_share_links_landing_step4")}
          </Typography>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            alignSelf="start"
            spacing={1}
            useFlexGap
            sx={{ pt: 2, width: { xs: "100%", sm: "auto" } }}
          >
            <Button
              variant="contained"
              color="primary"
              sx={{
                textTransform: "uppercase",
                padding: theme.spacing(1),
                px: theme.spacing(3),
                
              }}
              onClick={() => {
                dispatch(startGooglelogin(true));
                navigate(LOGIN);
              }}
            >
              <Box
                sx={{
                  background: "white",
                  display: "flex",
                  justifyContent: "center",
                  padding: "4px",
                  borderRadius: "4px",
                  marginRight: theme.spacing(1),
                }}
              >
                <img src={GoogleIcon} />
              </Box>{" "}
              {t("continue_with_google_title")}
            </Button>
          </Stack>
        </Stack>
      </Stack>
      {!downMd && (
        <video
          autoPlay
          loop
          muted
          style={{
            width: "65%",
            height: "80%",
            borderRadius: "10px",
            outline: "1px solid",
            objectFit: "cover",
            outlineColor:
              theme.palette.mode === "light"
                ? alpha("#BFCCD9", 0.5)
                : alpha("#9CCCFC", 0.1),
          }}
          src={LandingUploadlyPreviewMP4}
        >
          <source
            src={LandingUploadlyPreviewMP4}
            type="video/mp4"
            height={"100%"}
          />
          Your browser does not support the video tag.
        </video>
      )}
    </Box>
  );
};

export default React.memo(DownloadUploadContainer);

import Button from "@mui/material/Button";
import { Theme, useTheme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";
import Modal from "@mui/material/Modal";
import React, { useCallback } from "react";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Box, Card, IconButton, Stack } from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

export enum UploadOrShareOption {
  UPLOAD = 1,
  SHARE = 2,
}
interface PickUploadOrShareLinkDialogProps {
  open: boolean;
  handleOptionSelected: (option: UploadOrShareOption) => void;
  handleModalClose: (close: boolean) => void;
}

export const Title = styled.div`
  display: flex;
  justify-content: center;
  font-size: 1.6rem;
  font-weight: 500;
  color: black;
  margin-bottom: 16px;
  padding: 12px 12px 0 12px;
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      borderRadius: "25px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      width: "40%",
      marginTop: "24px",
      borderRadius: "12px",
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(8),
      [theme.breakpoints.down("sm")]: {
        width: "80%",
      },
    },
    addButton: {
      marginEnd: "12px",
      textTransform: "uppercase",
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "end",
      marginTop: "24px",
    },
  })
);

export default function PickUploadOrShareLinkDialog({
  open,
  handleOptionSelected,
  handleModalClose,
}: PickUploadOrShareLinkDialogProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();


  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={() => handleModalClose(false)}
        closeAfterTransition
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Stack
              display="flex"
              flexDirection={"row"}
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-around",
              }}
            >
              <Card
                variant="outlined"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  borderColor: theme.palette.primary.main,
                  width: "40%",
                  height: "100px",
                  cursor: "pointer",
                  ":hover": {
                    backgroundColor: "aliceblue",
                  },
                }}
                onClick={() => {
                  handleOptionSelected(UploadOrShareOption.UPLOAD);
                }}
              >
                <CloudUploadIcon
                  sx={{
                    height: "48px",
                    width: "48px",
                    color: "gray",
                  }}
                />

                <Typography
                  variant="caption"
                  sx={{
                    marginTop: theme.spacing(1),
                  }}
                >
                  {t("landing_tab_collect_file")}
                </Typography>
              </Card>
              <Card
                variant="outlined"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "40%",
                  height: "100px",
                  borderColor: theme.palette.primary.main,
                  cursor: "pointer",
                  ":hover": {
                    backgroundColor: "aliceblue",
                  },
                }}
                onClick={() => {
                  handleOptionSelected(UploadOrShareOption.SHARE);
                }}
              >
                <ShareIcon
                  sx={{
                    height: "48px",
                    width: "48px",
                    color: "gray",
                  }}
                />
                <Typography
                  variant="caption"
                  sx={{
                    marginTop: theme.spacing(1),
                  }}
                >
                  {t("landing_tab_share_file")}
                </Typography>
              </Card>
            </Stack>
            {/* <div className={classes.buttonContainer}>
              <Button
                className={classes.addButton}
                color="primary"
                onClick={() => handleModalClose(false)}
              >
                {t("cancel_action")}
              </Button>
              <Button className={classes.addButton} color="primary">
                {t("ok")}{" "}
              </Button>
            </div> */}
          </div>
        </Fade>
      </Modal>
    </>
  );
}

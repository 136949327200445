export const ROOT = "/";
export const LOGIN = "/login";
export const SIGNUP = "/register";
export const MAINBOARD = "/home";
export const UPLOAD = "/upload/:id";
export const STORAGES_FULL_PATH = "/home/storages/";
export const CONNECTED_STORAGES_FULL_PATH = "/home/storages/connected";
export const CONNECT_NEW_STORAGES_FULL_PATH = "/home/storages/connect";
export const UPLOADED_FILES_FULL_PATH = "/home/uploaded";
export const UPLOADLY_LINKS_FULL_PATH = "/home/links";
export const UPLOADLY_SHARE_LINKS_FULL_PATH = "/home/share-links";
export const UPLOADLY_LINK_FULL_PATH = "/home/link/:id";
export const UPLOADLY_LINK_FULL_PATH_NO_ID = "/home/link";
export const UPLOADLY_SHARE_LINK_FULL_PATH = "/home/share-link/:id";
export const UPLOADLY_SHARE_LINK_FULL_PATH_NO_ID = "/home/share-link";
export const CONNECTED_STORAGE_FULL_PATH = "/home/storage/:id";
export const CONNECTED_STORAGE_BASE_PATH = "/home/storage";
export const EMAIL_FORWARDING_FULL_PATH = "/home/email/";
export const RESET_PASSWORD = "/reset-password/:uid/:token";
export const RESET_PASSWORD_BASE_PATH = "/reset-password";
export const REQUEST_RESET_PASSWORD = "/request-reset-password";
export const PRICING = "/pricing";
export const PRIVACY_POLICY = "/privacy";
export const TERMS_CONDITIONS = "/terms-conditions";
export const PAYMENT = "/payment";
export const PAYMENT_WITH_ID = "/payment/:id";
export const SETTINGS = "/settings";
export const SHARE_LINK_PAGE = "/s/:token";

export const ACCOUNT_ACTIVATION = "/activate/:uid/:token";
export const ACCOUNT_ACTIVATION_BASE_PATH = "/activate";



export const PAYMENT_ANNUAL_STANDARD = "/payment/2";
export const PAYMENT_MONTHLY_STANDARD = "/payment/1";
export const PAYMENT_ANNUAL_BUSINESS = "/payment/4";
export const PAYMENT_MONTHLY_BUSINESS = "/payment/3";


export const GENERAL_SETTINGS = "/settings/general";
export const PREFERENCES_SETTINGS = "/settings/preferences";
export const SUBSCRIPTION_SETTINGS = "/settings/subscription";
export const ALLOWED_PATHS_ON_MOBILE = [
  ROOT,
  LOGIN,
  MAINBOARD,
  UPLOAD,
];



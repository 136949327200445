import {
  DEBUG,
  LAST_UPLOAD_ITEMS_RETRIEVED_IN_DASHBOARD,
  LAST_UPLOAD_LINKS_RETRIEVED_IN_DASHBOARD,
} from "configuration";
import {
  retrieveConnectedStoragesPagedGql,
  retrieveLastNUploadItemsGql,
  retrieveLastNUploadLinksGql,
  retrieveShareLinkFileItemsForIdPagedGql,
  retrieveUploadLinkLayoutSettingsGql,
  retrieveUploadLinksPagedGql,
} from "./queries";
import {
  UploadLinkConnectedStorage,
  UploadLink,
  UploadLinkLayoutSettings,
} from "models/uploadly";
import { ShareLinkFile } from "models/shareLink";
/* 
export function updateUploadLink(cache: any, uploadLink: UploadLink) {
  cache.writeQuery({
    query: retrieveUploadLinksPagedGql,
    variables: {
      page: 1,
      pageSize: 20,
      mergeIncomingFirst: true,
    },
    data: { allUploadLinksPaged: [uploadLink] },
  });
} */


/* 
export function deleteShareLinkFileFromCache(cache: any, collectionId: string) {
  if (DEBUG)
    console.log(
      "deleteShareLinkFileFromCache: for collection " +
        JSON.stringify(collectionId) +
        " from cache %o",
      cache
    );
  const existingCollections: any = cache.readQuery({
    query: retrieveAllCollectionsGql,
  });
  if (DEBUG)
    console.log(
      "deleteCollectionFromCache: found cache of  " +
        JSON.stringify(existingCollections)
    );
  if (existingCollections && existingCollections.collections) {
    const newCollectionsList = existingCollections.collections.filter(
      (coll: CollectionObj) => coll.id !== collectionId
    );
    if (DEBUG)
      console.log(
        "deleteCollectionFromCache: puhsing to cache list of " +
          newCollectionsList.length
      );
    cache.writeQuery({
      query: retrieveAllCollectionsGql,
      data: { collections: newCollectionsList },
    });
  }
} */


export function updateShareLinkFilesCacheUponDelete(
  cache: any,
  page: number,
  pageSize: number,
  shareLinkId: string,
  newPage: ShareLinkFile[]
) {
  const existingData: any = cache.readQuery({
    query: retrieveShareLinkFileItemsForIdPagedGql,
    variables: { id: shareLinkId },
  });

  //console.log("updateShareLinkFilesCacheUponDelete: existing data is %o", existingData);
  if (existingData) {
    console.log("updateShareLinkFilesCacheUponDelete: going in");

    const newCache = [
      ...[...existingData.shareLinkFilesPaged].slice(0, (page - 1) * pageSize),
      ...newPage,
    ];
    console.log("updateShareLinkFilesCacheUponDelete: will evict cache for id " + shareLinkId);
    cache.evict({
      id: "ROOT_QUERY",
      fieldName: "shareLinkFilesPaged",
      args: { id: shareLinkId },
    });
    
    console.log("updateShareLinkFilesCacheUponDelete: new cache to write is  %o", newCache);

    cache.writeQuery({
      query: retrieveShareLinkFileItemsForIdPagedGql,
      variables: { id: shareLinkId },
      data: { shareLinkFilesPaged: newCache },
    });
  }
}



export function uploadLastNUploadLinksItemsCache(
  cache: any,
  uploadLink: UploadLink
) {
  if (!uploadLink) {
    return;
  }
  const existingLastNUploadLinks: any = cache.readQuery({
    query: retrieveLastNUploadLinksGql,
    variables: {
      n: LAST_UPLOAD_LINKS_RETRIEVED_IN_DASHBOARD,
    },
  });

  if (existingLastNUploadLinks && existingLastNUploadLinks.lastNUploadLinks) {
    const cacheItemsCopy = [...existingLastNUploadLinks.lastNUploadLinks];
    if (
      existingLastNUploadLinks.lastNUploadLinks.length >=
      LAST_UPLOAD_LINKS_RETRIEVED_IN_DASHBOARD
    ) {
      cacheItemsCopy.pop();
    }
    cache.writeQuery({
      query: retrieveLastNUploadLinksGql,
      variables: {
        n: LAST_UPLOAD_LINKS_RETRIEVED_IN_DASHBOARD,
      },
      data: { lastNUploadLinks: [uploadLink, ...cacheItemsCopy] },
    });
  } else {
    if (DEBUG) {
      console.log(
        "uploadLastNUploadLinksItemsCache: cache is empty, so let's ignore this"
      );
    }
  }
}

export function updateUploadLinkLayoutSettingsCache(
  cache: any,
  uploadlinkId: string,
  uploadLinkLayoutSettings: UploadLinkLayoutSettings
) {
  if (DEBUG) {
    console.log(
      "updateUploadLinkLayoutSettingsCache for aggdata %o",
      uploadLinkLayoutSettings
    );
  }
  if (uploadlinkId && uploadLinkLayoutSettings) {
    cache.writeQuery({
      query: retrieveUploadLinkLayoutSettingsGql,
      data: { uploadLinkLayoutSettings: uploadLinkLayoutSettings },
      variables: { uploadLinkId: uploadlinkId },
    });
  }
}

export function addUploadLinkToCacheIfExists(
  cache: any,
  uploadLink: UploadLink
) {
  const existingUploadLinks: any = cache.readQuery({
    query: retrieveUploadLinksPagedGql,
    variables: {
      page: 1,
      pageSize: 20,
      mergeIncomingFirst: true,
    },
  });
  if (existingUploadLinks && existingUploadLinks.allUploadLinksPaged) {
    cache.writeQuery({
      query: retrieveUploadLinksPagedGql,
      variables: {
        page: 1,
        pageSize: 20,
        mergeIncomingFirst: true,
      },
      data: { allUploadLinksPaged: [uploadLink] },
    });
  }
}

export function checkIfUploadItemIsPresentInLastUploadItems(
  cache: any,
  uploadItemId: string
) {
  const existingUploadItems: any = cache.readQuery({
    query: retrieveLastNUploadItemsGql,
    variables: {
      n: LAST_UPLOAD_ITEMS_RETRIEVED_IN_DASHBOARD,
    },
  });
  if (DEBUG)
    console.log(
      "checkIfItemIsPresentInLastUploadItems: found cache of  " +
        JSON.stringify(existingUploadItems)
    );

  if (existingUploadItems && existingUploadItems.lastNUploadItems) {
    const result = existingUploadItems.lastNUploadItems.find(
      (item: any) => item.id == uploadItemId
    );
    return Boolean(result);
  }
  return false;
}

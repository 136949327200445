import { SubscriptionPlanIds } from "models/user";

export const PAYMENT_ROUTING_STANDARD_MONTHLY_PLAN_ID = "1"
export const PAYMENT_ROUTING_STANDARD_YEARLY_PLAN_ID = "2"
export const PAYMENT_ROUTING_BUSINESS_MONTHLY_PLAN_ID = "3"
export const PAYMENT_ROUTING_BUSINESS_YEARLY_PLAN_ID = "4"




export const SUBSCRIPTION_FREE_PLAN = {
  id: SubscriptionPlanIds.FREE,
  name: "Uploadly Free",
};

export const SUBSCRIPTION_STANDARD_PLAN = {
  id: SubscriptionPlanIds.STANDARD,
  name: "Uploadly Standard",
};

export const SUBSCRIPTION_BUSINESS_PLAN = {
  id: SubscriptionPlanIds.BUSINESS,
  name: "Uploadly Business",
};




export interface PlanTierDefinition{
    title: string;
    fullTitle: string;
    description: string;
}

export const getFreeTier = (translation: any) => {
  return {
    id: SubscriptionPlanIds.FREE,
    fullTitle: translation("tier_free_title"),
    title: translation("tier_free_title"),
    subheader: null,
    price: "0",
    currency: "$",
    monthlyPricing: 0,
    yearlyPricing: 0,
    yearlyPricingTotal: 0,
    description: [
      translation("tier_free_description_1"),
      translation("tier_free_description_2"),
      translation("tier_free_description_7"),
      translation("tier_free_description_3"),
      translation("tier_free_description_4"),
      translation("tier_free_description_5"),
      translation("tier_free_description_6"),
      translation("tier_free_description_7"),
    ],
    buttonText: translation("tier_free_enroll_action"),
    buttonVariant: "outlined",
  };
};

export const getStandardTier = (translation: any) => {
  return {
    id: SubscriptionPlanIds.STANDARD,
    fullTitle: translation("tier_standard_full_title"),
    title: translation("tier_standard_title"),
    subheader: translation("recommended_title"),
    price: "9",
    monthlyPricing: 9,
    yearlyPricing: 7,
    yearlyPricingTotal: 7*12,
    currency: "$",
    description: [
      translation("tier_standard_description_1"),
      translation("tier_standard_description_2"),
      translation("tier_standard_description_8"),
      translation("tier_standard_description_3"),
      translation("tier_standard_description_4"),
      translation("tier_standard_description_5"),
      translation("tier_standard_description_6"),
      translation("tier_standard_description_7"),
    ],
    buttonText: translation("tier_standard_enroll_action"),
    buttonVariant: "contained",
  };
};

export const getBusinessTier = (translation: any) => {
  return {
    id: SubscriptionPlanIds.BUSINESS,
    fullTitle: translation("tier_business_full_title"),
    title: translation("tier_business_title"),
    subheader: null,
    price: "18",
    monthlyPricing: 18,
    yearlyPricing: 14,
    yearlyPricingTotal: 14*12,
    currency: "$",
    description: [
        translation("tier_business_description_1"),
        translation("tier_business_description_2"),
        translation("tier_standard_description_8"),
        translation("tier_business_description_3"),
        translation("tier_business_description_5"),
        translation("tier_business_description_6"),
        translation("tier_business_description_7"),
    ],
    buttonText: translation("tier_standard_enroll_action"),
    buttonVariant: "outlined",
  };
};

export const getTiers = (translation: any) => {
  const tiers = [
    getFreeTier(translation),
    getStandardTier(translation),
    getBusinessTier(translation),
  ];

  return tiers;
};
/* 
const tiers = [
  {
    title: "Free",
    price: "0",
    description: [
      "1 Upload link",
      "7 days link expiry ",
      "100 uploads per link",
      "1 GB max upload per link",
      "1 GB of uploadly storage",
    ],
    buttonText: "Sign up for free",
    buttonVariant: "outlined",
  },
  {
    title: "Standard",
    subheader: "Recommended",
    price: "7,99",
    monthlyPricing: "9",
    yearlyPricing: "7",
    description: [
      "50 uploads links",
      "30 days link expiry ",
      "500 uploads per link",
      "5 GB max uploads per link",
      "10 GB of uploadly storage",
    ],
    buttonText: "Start now",
    buttonVariant: "contained",
  },
  {
    title: "Business",
    price: "30",
    monthlyPricing: "18",
    yearlyPricing: "14",
    description: [
      "50 users included",
      "30 GB of storage",
      "Help center access",
      "Phone & email support",
    ],
    buttonText: "Contact us",
    buttonVariant: "outlined",
  },
]; */

import {
  Box,
  CircularProgress,
  IconButton,
  Link,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  styled,
  useTheme,
} from "@mui/material"; 
import {
  StorageProviderType,
  UploadLink,
  UploadLinkStatus,
} from "models/uploadly";
import React, { useCallback, useEffect, useRef, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { retrieveUploadLinkForIdGql } from "graphql/queries";
import { useTranslation } from "react-i18next";
import {
  bytesToGigabytes,
  formatIso8601date,
  getRemainingTime,
  getSizeInHumeanReadableFormat,
} from "shared/dataUtils";
import { UPLOADLY_LINKS_FULL_PATH } from "navigation/Constants";
import UploadItemsContainer from "./UploadItemsContainer";
import { getUploadLinkUrlFromToken } from "configuration";
import CopyButtonToolTipped from "components/ui/CopyButtonToolTipped";
import {
  getIconForStorageProvider,
  getStorageProviderName,
} from "pages/storages/storageProviderIds";
import DestinationFolderBadge from "components/ui/DestinationFolderBadge";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import UploadLinkSettingsContainer from "./UploadLinkSettingsContainer";
import CircleIcon from "@mui/icons-material/Circle";
import { QRCodeCanvas, QRCodeSVG } from "qrcode.react";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import PublicUploadLinkPreview from "pages/PublicUploadLinkPreviewEditor";
import {
  getLinkStatusColor,
  getLinkStatusDotColor,
  getLinkStatusString,
} from "shared/utility";

const TAB_UPLOADS_LIST = 0;
const TAB_CONNECTED_STORAGE = 1;
const TAB_SETTINGS = 2;
const TAB_LAYOUT = 3;

const UploadlyLinkDetailContainer = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const [tabValue, setTabValue] = React.useState(TAB_UPLOADS_LIST);

  const uploadLinkResult = useQuery(retrieveUploadLinkForIdGql, {
    variables: {
      id: params.id,
    },
  });

  const containerRef = useRef(null);
  const childRef = useRef(null);
  const uploadLink: UploadLink =
    uploadLinkResult.data && uploadLinkResult.data.uploadLink
      ? uploadLinkResult.data.uploadLink
      : null;

  /*   const getLinkStatusString = useCallback(() => {
    if (uploadLink) {
      if (uploadLink.status == UploadLinkStatus.ACTIVE) {
        return t("upload_link_status_active");
      } else if (uploadLink.status == UploadLinkStatus.DISABLED) {
        return t("upload_link_status_disabled");
      } else if (uploadLink.status == UploadLinkStatus.EXPIRED) {
        return t("upload_link_status_expired");
      }
    }
  }, [uploadLink]);

  const getLinkStatusDotColor = useCallback(() => {
    if (uploadLink) {
      if (uploadLink.status == UploadLinkStatus.ACTIVE) {
        return "chartreuse";
      } else if (uploadLink.status == UploadLinkStatus.DISABLED) {
        return "coral";
      } else if (uploadLink.status == UploadLinkStatus.EXPIRED) {
        return "red";
      }
    }
  }, [uploadLink]); */

  const qrRef = useRef<any>();

  const downloadQRCode = useCallback(() => {
    const canvas = qrRef.current.querySelector("canvas");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = uploadLink.name + "-qrcode.png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }, [qrRef.current, uploadLink]);

  /* useEffect(() => {
    console.log(
      "entry change : use effect container" +
        containerRef.current +
        " child ref " +
        childRef.current
    );
    const observerCallback = (entries: any) => {
      entries.forEach((entry: any) => {
        // setIsVisible(entry.isIntersecting);
        console.log("entry change here  %o", entry);
         setIsIntersecting(entry.isIntersecting)
      });
    };

    const observer = new IntersectionObserver(observerCallback, {
      root: null,
      rootMargin: "0px",
      threshold: 0,
    });

    const target = childRef.current;
    if (target) {
      observer.observe(target);
    }

    // Cleanup function to unobserve the target element
    return () => {
      if (target) {
        observer.unobserve(target);
      }
    };
  }, [uploadLinkResult.data]);
 */

  if (
    !uploadLinkResult.loading &&
    !uploadLinkResult.error &&
    uploadLinkResult.data
  ) {
    return (
      <Box
        ref={containerRef}
        sx={{
          px: theme.spacing(8),
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          [theme.breakpoints.down("md")]: {
            px: theme.spacing(1),
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <IconButton
            onClick={() => {
              navigate(-1);
            }}
          >
            <ArrowBackIcon
              sx={{
                height: "32px",
                width: "32px",
              }}
            />
          </IconButton>

          <Typography
            variant="h3"
            sx={{
              marginLeft: theme.spacing(3),
            }}
          >
            {uploadLink.name}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            [theme.breakpoints.down("md")]: {
              flexDirection: "column",
              alignItems: "start",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{
                color: "gray",
              }}
            >
              {getIconForStorageProvider(
                uploadLink.integrations.length > 0
                  ? uploadLink.integrations[0].storageProvider
                  : StorageProviderType.UPLOADLY_STORAGE,
                {
                  height: "24px",
                  width: "24px",
                }
              )}
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{
                color: "gray",
                marginLeft: theme.spacing(1),
              }}
            >
              {getStorageProviderName(
                uploadLink.integrations.length > 0
                  ? uploadLink.integrations[0].storageProvider
                  : StorageProviderType.UPLOADLY_STORAGE
              )}
            </Typography>
            {uploadLink.integrations.length > 0 && (
              <DestinationFolderBadge
                destinationFolder={uploadLink.integrations[0].destinationFolder}
                storageProviderId={uploadLink.integrations[0].storageProvider}
                sx={{
                  marginLeft: theme.spacing(2),
                }}
              />
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{
                marginLeft: theme.spacing(1),
                color: "gray",
                [theme.breakpoints.down("md")]: {
                  display: "none",
                },
              }}
            >
              •
            </Typography>
            <FileUploadIcon
              sx={{
                color: "text.secondary",
                width: "16px",
                height: "16px",
                marginLeft: theme.spacing(1),
                [theme.breakpoints.down("md")]: {
                  marginLeft: theme.spacing(0),
                },
              }}
            />
            <Typography
              variant="subtitle2"
              sx={{
                color: "gray",
                marginLeft: theme.spacing(0.5),
              }}
            >
              {uploadLink.uploadsCount +
                "/" +
                uploadLink.maxUploadsCount +
                " " +
                t("uploads")}
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{
                marginLeft: theme.spacing(1),
                color: "gray",
              }}
            >
              •
            </Typography>
            <Tooltip
              title={
                bytesToGigabytes(uploadLink.totalUploadsSize) +
                "/" +
                getSizeInHumeanReadableFormat(uploadLink.maxTotalUploadsSize)
              }
            >
              <Box
                sx={{
                  position: "relative",
                  display: "inline-flex",
                  cursor: "default",
                  marginLeft: theme.spacing(1),
                  marginRight: theme.spacing(1),
                }}
              >
                <CircularProgress
                  variant="determinate"
                  size={"48px"}
                  value={
                    (uploadLink.totalUploadsSize /
                      uploadLink.maxTotalUploadsSize) *
                      100 >
                    100
                      ? 100
                      : (uploadLink.totalUploadsSize /
                          uploadLink.maxTotalUploadsSize) *
                        100
                  }
                />
                <Box
                  sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: "absolute",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    sx={{
                      color: "gray",
                    }}
                    variant="subtitle2"
                    component="div"
                    color="text.secondary"
                  >{`${Math.round(
                    (uploadLink.totalUploadsSize /
                      uploadLink.maxTotalUploadsSize) *
                      100
                  )}%`}</Typography>
                </Box>
              </Box>
            </Tooltip>

            <Typography
              variant="subtitle2"
              sx={{
                color: "gray",
              }}
            >
              •
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{
                marginLeft: theme.spacing(1),
                color: "gray",
              }}
            >
              {t("created_at", {
                date: formatIso8601date(uploadLink.createdAt),
              })}
            </Typography>
          </Box>
          {/* <Typography
            variant="subtitle2"
            sx={{
              marginLeft: theme.spacing(1),
              color: "gray",
            }}
          >
            {bytesToGigabytes(uploadLink.totalUploadsSize) +
              "/" +
              getSizeInHumeanReadableFormat(uploadLink.maxTotalUploadsSize)}
          </Typography> */}
        </Box>

        <Box
          ref={childRef}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            [theme.breakpoints.down("md")]: {
              flexDirection: "column",
              alignItems: "start",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <CircleIcon
              sx={{
                width: "12px",
                height: "12px",
                color: getLinkStatusDotColor(uploadLink.status),
              }}
            />
            <Typography
              variant="subtitle2"
              sx={{
                marginLeft: theme.spacing(1),
                color: "gray",
              }}
            >
              {getLinkStatusString(uploadLink.status, t)}
            </Typography>
            {uploadLink.status != UploadLinkStatus.EXPIRED && (
              <>
                <Typography
                  variant="subtitle2"
                  sx={{
                    marginLeft: theme.spacing(1),
                    color: "gray",
                  }}
                >
                  •
                </Typography>

                <Typography
                  variant="subtitle2"
                  sx={{
                    marginLeft: theme.spacing(1),
                    color: "gray",
                  }}
                >
                  {t("expires_in", {
                    expiry: getRemainingTime(uploadLink.expiryDate, t),
                  })}
                </Typography>
              </>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              [theme.breakpoints.down("md")]: {
                mt: theme.spacing(1),
              },
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{
                marginLeft: theme.spacing(1),
                color: "gray",
                [theme.breakpoints.down("md")]: {
                  display: "none",
                },
              }}
            >
              •
            </Typography>
            <Link
              component="button"
              variant="body2"
              sx={{
                textAlign: "start",
                display: "flex",
                wordBreak: "break-word",
                marginLeft: theme.spacing(1),
              }}
              onClick={() => {
                window.open(
                  getUploadLinkUrlFromToken(uploadLink.token),
                  "_blank"
                );
              }}
            >
              {getUploadLinkUrlFromToken(uploadLink.token)}
            </Link>
            <CopyButtonToolTipped
              sx={{
                marginLeft: theme.spacing(1),
              }}
              onClick={() => {
                navigator.clipboard.writeText(
                  getUploadLinkUrlFromToken(uploadLink.token)
                );
              }}
            />
            <Tooltip title={t("download_qr_code_title")}>
              <IconButton onClick={downloadQRCode}>
                <QrCode2Icon />
              </IconButton>
            </Tooltip>
          </Box>
          <div style={{ display: "none" }} ref={qrRef}>
            <QRCodeCanvas
              value={getUploadLinkUrlFromToken(uploadLink.token)}
              size={600}
            />
          </div>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginTop: theme.spacing(1),
            }}
          ></Box>
        </Box>

        <Tabs
          sx={
            {
              /*   position: "sticky",
            zIndex: 10,
            top: "10px" */
            }
          }
          value={tabValue}
          onChange={(event: React.SyntheticEvent, newValue: any) => {
            // console.log("Got a change here " + JSON.stringify(newValue));
            setTabValue(newValue);
          }}
          variant="scrollable"
          scrollButtons={false}
          aria-label="scrollable prevent tabs example"
        >
          <Tab value={TAB_UPLOADS_LIST} label={t("uploads_title")} />
          <Tab value={TAB_SETTINGS} label={t("upload_link_settings_title")} />
          {/* <Tab
            value={TAB_CONNECTED_STORAGE}
            label={t("connected_storage_title")}
          /> */}
          <Tab value={TAB_LAYOUT} label={t("uploadlink_layout_tab_title")} />
        </Tabs>
        {tabValue == TAB_UPLOADS_LIST && params.id && (
          <UploadItemsContainer uploadLinkId={params.id} />
        )}
        {tabValue == TAB_SETTINGS && uploadLink && (
          <UploadLinkSettingsContainer uploadLink={uploadLink} />
        )}
        {tabValue == TAB_LAYOUT && uploadLink && (
          <PublicUploadLinkPreview uploadLink={uploadLink} />
        )}
      </Box>
    );
  } else {
    return <></>;
  }
};

export default UploadlyLinkDetailContainer;

import {
  Box,
  Button,
  Card,
  IconButton,
  Link,
  Switch,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import {
  StorageProviderType,
  UploadLink,
  UploadLinkStatus,
} from "models/uploadly";
import React, { useRef, useState } from "react";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import {
  Unstable_NumberInput as BaseNumberInput,
  NumberInputProps,
  numberInputClasses,
} from "@mui/base/Unstable_NumberInput";
import { styled } from "@mui/system";
import {
  StyledButton,
  StyledInputElement,
  StyledInputRoot,
} from "shared/styles";
import TextPicker from "components/ui/TextPicker";
import MultiSelectField from "components/ui/MultiSelectField";
import { LoadingButton } from "@mui/lab";
import dayjs, { Dayjs, extend } from "dayjs";
import { useTranslation } from "react-i18next";
import { upload } from "@testing-library/user-event/dist/upload";
import {
  ONE_GB,
  ONE_MB,
  appendDeltaAndFormatDate,
  currentTimestamp,
  isDifferenceSmallerThanDelta,
  iso8601dateToTimestamp,
} from "shared/dataUtils";
import {
  deleteShareLinkGql,
  updateShareLinkkGql,
  updateUploadLinkGql,
} from "graphql/mutations";
import { useMutation } from "@apollo/client";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { showSnackbar, showUpgradePlanDialog } from "store/view";
import { useDispatch, useSelector } from "react-redux";
import {
  SubscriptionPlanIds,
  getUserMaxAllowedSizePerUploadLink,
  getUserMaxAllowedUploadCountsPerLink,
  getUserMaxShareLinkExpiryDuration,
  getUserMaxUploadLinkExpiryDuration,
} from "models/user";
import { RootState } from "store";
import { ShareLink, ShareLinkStatus } from "models/shareLink";
import { DeleteOutline } from "@mui/icons-material";
import SimpleConfirmationDialog from "components/ui/dialogs/SimpleConfirmationDialog";
import { useNavigate } from "react-router-dom";
import {
  SHARE_LINK_PAGE,
  UPLOADLY_SHARE_LINKS_FULL_PATH,
} from "navigation/Constants";
interface ShareLinkSettingsContainerProps {
  shareLink: ShareLink;
}

const ShareLinkSettingsContainer = ({
  shareLink,
}: ShareLinkSettingsContainerProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [updateShareLink, updateShareLinkResult] = useMutation(
    updateShareLinkkGql
  );

  const [deleteShareLink, deleteShareLinkResult] = useMutation(
    deleteShareLinkGql, 
    {
        variables: {
            id: shareLink.id
        }
    }
  );
  const [
    linkExpirationDate,
    setLinkExpirationDate,
  ] = React.useState<Dayjs | null>(
    dayjs(
      shareLink.expiryDate ? shareLink.expiryDate : currentTimestamp() * 1000
    )
  );

  const [newShareLinkTitle, setNewShareLinkTitle] = useState<string | null>(
    null
  );
  const [newShareLinkMessage, setNewShareLinkMessage] = useState<string | null>(
    null
  );

  const [enableDisableState, setEnableDisableState] = useState<boolean | null>(
    null
  );
  const [openDeleteLinkConfirmation, setOpenDeleteLinkConfirmation] = useState(
    false
  );

  const user = useSelector((state: RootState) => state.auth.user);

  const shouldEnableSave = React.useCallback(() => {
    return (
      Boolean(newShareLinkTitle) ||
      Boolean(newShareLinkMessage) ||
      enableDisableState != null ||
      iso8601dateToTimestamp(shareLink.expiryDate) != linkExpirationDate?.unix()
    );
  }, [
    linkExpirationDate,
    shareLink,
    newShareLinkTitle,
    newShareLinkMessage,
    enableDisableState,
  ]);

  const handleSaveUpdate = React.useCallback(() => {
    updateShareLink({
      variables: {
        input: {
          id: shareLink.id,
          title:
            newShareLinkTitle && newShareLinkTitle != shareLink.title
              ? newShareLinkTitle
              : undefined,
          message:
            newShareLinkMessage && newShareLinkMessage != shareLink.message
              ? newShareLinkMessage
              : undefined,
          expiryDate:
            iso8601dateToTimestamp(shareLink.expiryDate) !=
            linkExpirationDate?.unix()
              ? linkExpirationDate?.unix()
              : undefined,
          status:
            enableDisableState != null
              ? enableDisableState
                ? ShareLinkStatus.DISABLED
                : ShareLinkStatus.ACTIVE
              : undefined,
        },
      },
    })
      .then((result) => {
        dispatch(
          showSnackbar({
            message: t("update_successful_msg"),
            action: "",
            onAction: null,
            loading: false,
          })
        );
        if (result.data.updateShareLink.success) {
          resetUpdatedFields();
        }
      })
      .catch((e) => {
        dispatch(
          showSnackbar({
            message: t("update_failed_msg"),
            action: "",
            onAction: null,
            loading: false,
          })
        );
      });
  }, [
    newShareLinkTitle,
    newShareLinkMessage,
    linkExpirationDate,
    enableDisableState,
  ]);

  const resetUpdatedFields = React.useCallback(() => {
    setNewShareLinkTitle(null);
    setNewShareLinkMessage(null);
    setEnableDisableState(null);
  }, []);

  const handleDeleteShareLink = React.useCallback(() => {
    deleteShareLink()
      .then((result) => {
        if (result.data && result.data.deleteShareLink.success) {
          dispatch(
            showSnackbar({
              message: t("operation_success_generic"),
              loading: false,
            })
          );
          navigate(UPLOADLY_SHARE_LINKS_FULL_PATH);
        } else {
          dispatch(
            showSnackbar({
              message: t("operation_failed_generic"),
              loading: false,
            })
          );
        }
      })
      .catch((e) => {
        dispatch(
          showSnackbar({
            message: t("operation_failed_generic"),
            loading: false,
          })
        );
      });
  }, []);

  return (
    <Box
      sx={{
        py: theme.spacing(2),
        paddingTop: theme.spacing(0.5),
        width: "100%",
        height: "100%",
      }}
    >
      <Card
        sx={{
          paddingTop: theme.spacing(4),

          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            px: "5%",
          }}
        >
          <ShareLinkEnabledDisableSetting
            key={"enable-disable-setting"}
            shareLink={shareLink}
            onStateChanged={(checked: boolean) => {
              setEnableDisableState(checked);
            }}
          />

          <Box
            key={"expiry-date-setting"}
            sx={{
              paddingTop: theme.spacing(3),
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                maxWidth: "30%",
              }}
            >
              <Typography
                sx={{
                  flex: "1",
                  boxSizing: "border-box",
                  wordWrap: "break-word",
                  fontWeight: "bold",
                  color: theme.palette.primary.main,
                }}
                variant="subtitle2"
              >
                {t("upload_link_expiry_date")}
              </Typography>
              <Typography
                sx={{
                  flex: "1",
                  boxSizing: "border-box",
                  wordWrap: "break-word",
                  color: "gray",
                }}
                variant="caption"
              >
                {t("upload_link_expiry_date_description")}
              </Typography>
            </Box>

            <Box
              sx={{
                width: "60%",
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DateTimePicker"]}>
                  <DateTimePicker
                    sx={{ width: "100%" }}
                    label={null}
                    disablePast
                    value={linkExpirationDate}
                    onChange={(newValue) => {
                      if (!newValue) {
                        return;
                      }
                      // console.log("got a new value " + newValue);
                      const maxAllowedExpiryDuration = getUserMaxShareLinkExpiryDuration(
                        user
                      );
                      console.log(
                        "max expiry duration is  " + maxAllowedExpiryDuration
                      );

                      const creationDate = new Date(shareLink.createdAt);
                      console.log("creationDate  " + shareLink.createdAt);
                      console.log("shareLink  %o", shareLink);

                      const selectedDate = newValue?.toDate();
                      if (
                        !isDifferenceSmallerThanDelta(
                          creationDate,
                          selectedDate,
                          maxAllowedExpiryDuration
                        )
                      ) {
                        if (
                          user?.subscriptionPlan.id == SubscriptionPlanIds.FREE
                        ) {
                          dispatch(
                            showUpgradePlanDialog({
                              show: true,
                              message: t("upgrade_plan_link_expiry_message", {
                                date: appendDeltaAndFormatDate(
                                  shareLink.createdAt,
                                  maxAllowedExpiryDuration
                                ),
                              }),
                            })
                          );
                        } else {
                          dispatch(
                            showSnackbar({
                              message: t(
                                "max_allowed_duration_paid_subscriber_message"
                              ),
                              action: "",
                              onAction: null,
                              loading: false,
                            })
                          );
                        }
                      }
                      setLinkExpirationDate(newValue);
                    }}
                    /*  onSelectedSectionsChange={(newValue) => {
                        console.log("got a new value  onSelectedSectionsChange" + newValue);
                      }} */
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Box>
          </Box>
          <ShareLinkTitleSetting
            key={"title-setting"}
            shareLink={shareLink}
            onTextChanged={(newName: string) => {
              setNewShareLinkTitle(newName);
            }}
          />
          <ShareLinkMessageSetting
            key={"message-setting"}
            shareLink={shareLink}
            onTextChanged={(newName: string) => {
              setNewShareLinkMessage(newName);
            }}
          />
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "end",
            py: theme.spacing(1),
            px: theme.spacing(2),
          }}
        >
          <Button
            sx={{
              color: "gray",
            }}
            onClick={() => {
              setOpenDeleteLinkConfirmation(true);
            }}
          >
            <DeleteOutline
              sx={{
                marginRight: theme.spacing(2),
              }}
            />
            {t("delete_link_title")}
          </Button>
          <LoadingButton
            disabled={!shouldEnableSave()}
            loading={updateShareLinkResult.loading}
            onClick={handleSaveUpdate}
          >
            {t("save_action")}
          </LoadingButton>
          {openDeleteLinkConfirmation && (
            <SimpleConfirmationDialog
              open={openDeleteLinkConfirmation}
              title={t("delete_link_confirmation_title")}
              description={t("delete_link_confirmation_description")}
              handleModalClose={() => {
                setOpenDeleteLinkConfirmation(false);
              }}
              handleOk={() => {
                handleDeleteShareLink();
                setOpenDeleteLinkConfirmation(false);
              }}
            />
          )}
        </Box>
      </Card>
    </Box>
  );
};

const maxSizeOptions: any = {};

maxSizeOptions["100mo"] = { label: "100 Mo", value: ONE_MB * 100 };
maxSizeOptions["500mo"] = { label: "500 Mo", value: ONE_MB * 500 };
maxSizeOptions["1go"] = { label: "1 Go", value: ONE_GB };
maxSizeOptions["2go"] = { label: "2 Go", value: ONE_GB * 2 };
maxSizeOptions["5go"] = { label: "5 Go", value: ONE_GB * 5 };
maxSizeOptions["unlimited"] = {
  label: "Unlimited",
  value: Number.MAX_SAFE_INTEGER,
};

const supportedMaxSizes = ["100mo", "500mo", "1go", "2go", "5go", "unlimited"];

const allowedFilesObject: any = {};

allowedFilesObject["all"] = { label: "All", value: "all" };
allowedFilesObject["image"] = { label: "Image", value: "image" };
allowedFilesObject["video"] = { label: "Video", value: "video" };
allowedFilesObject["pdf"] = { label: "PDF", value: "pdf" };

const supportedFileTypes = ["all", "image", "video", "pdf"];
const supportedFileTypesForGooglePhotos = ["all", "image", "video"];

const CustomNumberInput = React.forwardRef(function CustomNumberInput(
  props: NumberInputProps,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  return (
    <BaseNumberInput
      slots={{
        root: StyledInputRoot,
        input: StyledInputElement,
        incrementButton: StyledButton,
        decrementButton: StyledButton,
      }}
      slotProps={{
        incrementButton: {
          children: "▴",
        },
        decrementButton: {
          children: "▾",
        },
      }}
      {...props}
      ref={ref}
    />
  );
});

interface ShareLinkEnabledDisableSettingProps
  extends ShareLinkSettingsContainerProps {
  onStateChanged: (enabled: boolean) => void;
}

const ShareLinkEnabledDisableSetting = ({
  shareLink,
  onStateChanged,
}: ShareLinkEnabledDisableSettingProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        paddingTop: theme.spacing(3),
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography
          sx={{
            flex: "1",
            boxSizing: "border-box",
            wordWrap: "break-word",
            fontWeight: "bold",
            color: theme.palette.primary.main,
          }}
          variant="subtitle2"
        >
          {t("upload_link_disabled_setting_title")}
        </Typography>
        <Typography
          sx={{
            flex: "1",
            boxSizing: "border-box",
            wordWrap: "break-word",
            color: "gray",
          }}
          variant="caption"
        >
          {t("upload_link_disabled_setting_description")}
        </Typography>
      </Box>

      <Switch
        defaultChecked={Boolean(shareLink.status)}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          onStateChanged(event.target.checked);
        }}
      />
    </Box>
  );
};

interface ShareLinkTitleSettingProps extends ShareLinkSettingsContainerProps {
  onTextChanged: (newName: string) => void;
}

const ShareLinkTitleSetting = ({
  shareLink,
  onTextChanged,
}: ShareLinkTitleSettingProps) => {
  const theme = useTheme();
  const [nameInput, setNameInput] = React.useState(shareLink.title);
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        marginTop: theme.spacing(2),
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography
          sx={{
            flex: "1",
            boxSizing: "border-box",
            wordWrap: "break-word",
            fontWeight: "bold",
            color: theme.palette.primary.main,
          }}
          variant="subtitle2"
        >
          {t("title")}
        </Typography>
        <Typography
          sx={{
            flex: "1",
            boxSizing: "border-box",
            wordWrap: "break-word",
            color: "gray",
          }}
          variant="caption"
        ></Typography>
      </Box>

      <TextField
        defaultValue={shareLink.title}
        value={nameInput}
        placeholder="holder"
        onChange={(e) => {
          setNameInput(e.target.value);
          onTextChanged(e.target.value);
        }}
        sx={{
          width: "60%",
        }}
      />
    </Box>
  );
};

const ShareLinkMessageSetting = ({
  shareLink,
  onTextChanged,
}: ShareLinkTitleSettingProps) => {
  const theme = useTheme();
  const [messageInput, setMessageInput] = React.useState(shareLink.message);
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        marginTop: theme.spacing(2),
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography
          sx={{
            flex: "1",
            boxSizing: "border-box",
            wordWrap: "break-word",
            fontWeight: "bold",
            color: theme.palette.primary.main,
          }}
          variant="subtitle2"
        >
          {t("message_title")}
        </Typography>
        <Typography
          sx={{
            flex: "1",
            boxSizing: "border-box",
            wordWrap: "break-word",
            color: "gray",
          }}
          variant="caption"
        ></Typography>
      </Box>

      <TextField
        defaultValue={shareLink.title}
        value={messageInput}
        multiline
        rows={4} // Number of initial rows
        maxRows={20} // Maximum number of rows before scrolling
        onChange={(e) => {
          setMessageInput(e.target.value);
          onTextChanged(e.target.value);
        }}
        sx={{
          width: "60%",
          "& .MuiInputBase-input::placeholder": {},
          padding: 0,
          "& .MuiInputBase-root": {
            padding: "8px",
          },
          "& .MuiInputBase-input": {
            padding: 0,
            alignContent: "top",
            textAlign: "top",
          },
        }}
      />
    </Box>
  );
};

export default React.memo(ShareLinkSettingsContainer);
